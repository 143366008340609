import React, { useState, useEffect, useRef } from 'react';
import ClientEmailInput from './ClientEmailInput'; // Import the ClientEmailInput component

const ClientInfo = ({ setClientInfo, setAgentInfo, setSelectedAgentId }) => {  // Accept the props here
  const [agentName, setAgentName] = useState('');
  const [agentEmail, setAgentEmail] = useState('');
  const [agentInfoLoaded, setAgentInfoLoaded] = useState(false); // To track if agent info has been loaded
  const [allAgents, setAllAgents] = useState([]); // Store all agents data for dropdown
  const [selectedAgent, setSelectedAgent] = useState(''); // Store selected agent from dropdown
  const userRole = localStorage.getItem('userRole'); // Get the user role from localStorage
  const [clientEmail, setClientEmail] = useState({ handle: '', website: '', domain: 'com' });
  const [clientName, setClientName] = useState(''); // New state for client name

  const areaCodeRef = useRef(null); // Ref for area code input
  const prefixRef = useRef(null); // Ref for prefix input
  const lineNumberRef = useRef(null); // Ref for line number input

  const [phoneNumber, setPhoneNumber] = useState({
    areaCode: '',
    prefix: '',
    lineNumber: ''
  });

  // Update parent state when agent info changes
  useEffect(() => {
    setAgentInfo({
      agentName: agentName,
      agentEmail: agentEmail,
    });
  }, [agentName, agentEmail, setAgentInfo]);

  useEffect(() => {
    setClientInfo((prev) => ({
      ...prev,
      name: clientName, // Pass client name to parent
      email: clientEmail,
      phoneNumber: phoneNumber,
    }));
  }, [clientName, clientEmail, phoneNumber, setClientInfo]);
  


  const fetchAgentInfo = async (userId) => {
    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/searchByUserId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
      const data = await response.json();
      console.log('Agent info:', data);
  
      if (data.success && data.data && data.data.length > 0) {
        const agentData = data.data;
        const defaultAgent = data.agnName;
        
        setAgentName(defaultAgent);
        const defaultAgentInfo = agentData.find(agent => agent.lagnname === defaultAgent) || {};
        setAgentEmail(defaultAgentInfo.email || '');
        setSelectedAgentId(defaultAgentInfo.id); // Set the selected agent ID
  
        setAllAgents(agentData);
        setSelectedAgent(defaultAgent);
      } else {
        console.error('Agent info not found or invalid data format');
      }
      setAgentInfoLoaded(true);
    } catch (error) {
      console.error('Error fetching agent info:', error);
      setAgentInfoLoaded(true);
    }
  };

  // useEffect to fetch agent info on page load
  useEffect(() => {
    const userId = localStorage.getItem('userId'); // Get userId from localStorage
    if (userId) {
      fetchAgentInfo(userId); // Fetch agent info on page load
    } else {
      console.error('User ID not found in localStorage');
      setAgentInfoLoaded(true); // Mark as loaded even if userId is missing
    }
  }, []); // Empty dependency array ensures this runs only on component mount

  const handleAgentChange = (e) => {
    const selected = e.target.value;
    setSelectedAgent(selected);
  
    const selectedAgentInfo = allAgents.find(agent => agent.lagnname === selected) || {};
    setAgentName(selectedAgentInfo.lagnname || ''); // Set the selected agent's name
    setAgentEmail(selectedAgentInfo.email || ''); // Set the selected agent's email
    setSelectedAgentId(selectedAgentInfo.id); // Set the selected agent's ID
  };
  
  // Function to handle input and move focus
  const handlePhoneNumberChange = (e, field, nextRef, prevRef) => {
    const value = e.target.value.replace(/\D/g, ''); // Allow only numbers
    if (value.length <= e.target.maxLength) {
      setPhoneNumber(prev => ({ ...prev, [field]: value }));
    }

    // Jump to the next input if the current input is filled
    if (value.length === e.target.maxLength && nextRef) {
      nextRef.current.focus();
    }

    // Handle backspace to move to previous input
    if (e.key === 'Backspace' && value.length === 0 && prevRef) {
      prevRef.current.focus();
    }
  };

  return (
<div className="client-info-group">
  <h4>Client Information</h4>
  <div className="input-row">
    <div className="input-group">
      <label htmlFor="client_name">Name on Application Package</label>
      <input
  style={{borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}
  type="text"
  id="client_name"
  name="client_name"
  value={clientName} // Bind the value to clientName state
  onChange={(e) => setClientName(e.target.value)} // Update clientName state
  autoComplete="off"
  required
/>
    </div>
    <div className="input-group">
      <label htmlFor="phone-number">Client Phone Number</label>
      <div className="phone-number-container">
        <input
          type="text"
          id="area-code"
          ref={areaCodeRef}
          maxLength="3"
          required
          autoComplete="off"
          value={phoneNumber.areaCode}
          onChange={(e) => handlePhoneNumberChange(e, 'areaCode', prefixRef)}
          onKeyDown={(e) => handlePhoneNumberChange(e, 'areaCode', prefixRef)}
        />
        <input
          type="text"
          id="prefix"
          ref={prefixRef}
          maxLength="3"
          required
          autoComplete="off"
          value={phoneNumber.prefix}
          onChange={(e) => handlePhoneNumberChange(e, 'prefix', lineNumberRef, areaCodeRef)}
          onKeyDown={(e) => handlePhoneNumberChange(e, 'prefix', lineNumberRef, areaCodeRef)}
        />
        <input
          type="text"
          id="line-number"
          ref={lineNumberRef}
          maxLength="4"
          required
          autoComplete="off"
          value={phoneNumber.lineNumber}
          onChange={(e) => handlePhoneNumberChange(e, 'lineNumber', null, prefixRef)}
          onKeyDown={(e) => handlePhoneNumberChange(e, 'lineNumber', null, prefixRef)}
        />
      </div>
    </div>
  </div>

  {/* Use ClientEmailInput component here */}
{/* Pass the email state and setter function to ClientEmailInput */}
<ClientEmailInput
  emailHandle={clientEmail.handle}
  emailWebsite={clientEmail.website}
  emailDomain={clientEmail.domain}
  setClientEmail={setClientEmail}
/>

{agentInfoLoaded && (
  <div className="agent-info-group">
    <hr />
    <h4>Agent Information</h4>

    {userRole === 'AGT' ? (
      <>
        <div className="input-row"> {/* Agent Name and Email in a row */}
          <div className="input-group">
            <label htmlFor="agent_name">Agent Name</label>
            <input
              type="text"
              id="agent_name"
              name="agent_name"
              value={agentName}
              readOnly
              style={{
                padding: '8px',
                fontSize: '12px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                width: '100%',
              }}
            />
          </div>
          <div className="input-group">
            <label htmlFor="agent_email">Agent Email</label>
            <input
              type="email"
              id="agent_email"
              name="agent_email"
              value={agentEmail}
              onChange={(e) => setAgentEmail(e.target.value)} // Make the input editable
              style={{
                padding: '8px',
                fontSize: '12px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                width: '100%',
              }}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="input-row"> {/* Agent Name and Email in a row */}
          <div className="input-group">
            <label htmlFor="agent_name">Agent Name</label>
            <select
              id="agent_name"
              value={selectedAgent}
              onChange={handleAgentChange}
              style={{
                padding: '8px',
                fontSize: '12px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                width: '100%',
                backgroundColor: 'white',
                fontFamily: 'Calibri',
              }}
            >
              {allAgents.map((agent, index) => (
                <option key={index} value={agent.lagnname}>
                  {agent.lagnname}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="agent_email">Agent Email</label>
            <input
              type="email"
              id="agent_email"
              name="agent_email"
              value={agentEmail}
              onChange={(e) => setAgentEmail(e.target.value)} // Make the input editable
              style={{
                padding: '8px',
                fontSize: '12px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                width: '100%',
              }}
            />
          </div>
        </div>
      </>
    )}
  </div>
)}


  {!agentInfoLoaded && <p>Loading agent information...</p>}
</div>

  );
};

export default ClientInfo;
