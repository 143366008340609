import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaBars, FaSignOutAlt } from 'react-icons/fa';
import styled from 'styled-components';
import logo from '../../img/globe1.png';
import '../../App.css';
import './Navbar.css';

function CustomNavbar({ currentPage }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [screenName, setScreenName] = useState('');
    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');

    useEffect(() => {
        const storedScreenName = localStorage.getItem('screenName');
        if (storedScreenName) {
            setScreenName(storedScreenName); // Set screen name from localStorage
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userId');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('screenName');
        localStorage.removeItem('agency');
        localStorage.removeItem('agnName');
        navigate('/adminlogin'); // Redirect to the admin login page
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <Navbar className="navbar-sticky-admin" collapseOnSelect expand="md">
                <Container style={{ maxWidth: '1400px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <HamburgerIcon  onClick={toggleMenu}>
                            {<FaBars />}
                        </HamburgerIcon>
                        <Navbar.Brand href="/admin/">
                            <img src={logo} width="45" height="45" alt="Logo" />
                        </Navbar.Brand>
                        <span style={{ color: 'white', marginLeft: '15px' }}>{currentPage}</span>
                    </div>
                    <div className="navbar-links">
    <a href="/admin/verify" style={reportActivityLinkStyle} className="navbar-link">
       Verification
    </a>
    {screenName && (
        <>
            <span style={{ fontSize: '1.75rem', marginLeft: '20px', color: 'white', marginBottom: '3px' }}>|</span>
            <span style={{ color: 'white', marginLeft: '10px' }}>
                {screenName}
            </span>
        </>
    )}
    <button className="btn" onClick={handleLogout} style={{ marginLeft: '15px', color: 'white' }}>
        <FaSignOutAlt /> Logout
    </button>
</div>

                </Container>
            </Navbar>

            <SideNav  isOpen={menuOpen}>
                <NavItem hidden href="/adminaccount" onClick={toggleMenu}>
                    <FaUserCircle size={24} />
                </NavItem>
                <NavItem href="/admin/" onClick={toggleMenu}>Dashboard</NavItem>
                <NavItem href="/admin/verify" onClick={toggleMenu}>Verification</NavItem>
                <NavItem href="/admin/release" onClick={toggleMenu}>Release</NavItem>
                <NavItem onClick={handleLogout} style={{ marginTop: 'auto', paddingBottom: '40px' }}>
                    Logout
                </NavItem>
            </SideNav>
            <Overlay isOpen={menuOpen} onClick={toggleMenu} />
        </>
    );
}

const HamburgerIcon = styled.div`
    font-size: 20px;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    z-index: 2000;
`;

const SideNav = styled.div`
    height: 100%;
    width: 160px;
    position: fixed;
    top: 0;
    left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
    background-color: #319B42;
    overflow-x: hidden;
    transition: 0.3s;
    z-index: 1500;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
`;

const NavItem = styled.a`
    padding: 8px 8px 8px 8px;
    text-decoration: none;
    font-size: 15px;
    color: white;
    display: block;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        color: grey;
    }
`;

const Overlay = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
`;

const reportActivityLinkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1rem',
    marginRight: '15px'
};

export default CustomNavbar;
