import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaBars, FaUser, FaUserCircle } from 'react-icons/fa';
import logo from '../img/globe1.png';
import '../App.css';
import './Navbar.css';
import phoneIcon from '../img/phone.svg';
import { useSnowfall } from '../contexts/SnowfallContext';
const ExternalLinkIcon = () => (
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="external-link-icon"
        style={{ marginLeft: '5px', width: '16px', height: '16px', verticalAlign: 'middle' }}
    >
        <path
            d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


function CustomNavbar({ currentPage }) {
    const [userProfilePic, setUserProfilePic] = useState(null);
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const popupWindows = useRef([]);
    const { isSnowfallEnabled, setIsSnowfallEnabled } = useSnowfall();
    const [isPhoneOpen, setIsPhoneOpen] = useState(false);
    const [isPresOpen, setIsPresOpen] = useState(false);
    const phoneWindowRef = useRef(null);
    const presWindowRef = useRef(null);
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (phoneWindowRef.current && phoneWindowRef.current.closed) {
                setIsPhoneOpen(false);
                phoneWindowRef.current = null;
            }
            if (presWindowRef.current && presWindowRef.current.closed) {
                setIsPresOpen(false);
                presWindowRef.current = null;
            }
        }, 1000);
    
        return () => clearInterval(interval);
    }, []);
    
    useEffect(() => {
        const savedSnowfallState = localStorage.getItem('snowfallEnabled');
        if (savedSnowfallState !== null) {
            setIsSnowfallEnabled(JSON.parse(savedSnowfallState));
        }
    }, [setIsSnowfallEnabled]);

    const toggleSnowfall = () => {
        setIsSnowfallEnabled((prev) => {
            const newState = !prev;
            localStorage.setItem('snowfallEnabled', JSON.stringify(newState));
            return newState;
        });
    };


    useEffect(() => {
        console.log('Component mounted. Setting accountMenuOpen to false.');
        setAccountMenuOpen(false); // Ensure the menu starts closed
    }, []);
    
    useEffect(() => {
        const fetchProfilePic = async () => {
            try {
                const userId = localStorage.getItem('userId');
                console.log('Fetching profile picture for user ID:', userId);
                if (!userId) return;
    
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/get-profile-pic', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId }),
                });
    
                const data = await response.json();
                if (data.success) {
                    setUserProfilePic(data.profpic);
                    console.log('Profile picture fetched successfully:', data.profpic);
                } else {
                    console.error('Failed to fetch profile picture:', data.message);
                }
            } catch (error) {
                console.error('Error fetching profile picture:', error);
            }
        };
    
        fetchProfilePic();
    }, []);
    
    
    
    const [menuOpen, setMenuOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? savedTheme === 'dark' : false;
    });
    const [greeting, setGreeting] = useState('');
    const menuRef = useRef(null);

// Utility function to determine if a link is external
const isExternalLink = (url) => /^https?:\/\//.test(url);

const categories = {
    Production: [
        { name: 'Report Activity', href: '/reports' },
        { name: 'Verify', href: '/verify' },
        { name: 'Leaderboards', href: '/leaderboard' },
    ],
    Recruiting: [
        { name: 'Pipeline', href: '/pipeline' },
        { name: 'M.O.R.E.', href: '/more' },
    ],
    Training: [
        {
            name: 'Training Course',
            href: 'https://ariaslife.mykajabi.com/',
            external: isExternalLink('https://ariaslife.mykajabi.com/'), // Automatically detects external link
        },
        { name: 'Tutorials', href: '/tutorials', external: isExternalLink('/tutorials') },
        { name: 'Release', href: '/release', external: isExternalLink('/release') },
    ],
};


const renderLinks = (links) =>
    links.map((link) => {
        if (link.onClick) {
            return (
                <span
                    key={link.name}
                    onClick={link.onClick}
                    className="navbar-dropdown-item"
                    style={{ cursor: 'pointer' }}
                >
                    {link.name}
                </span>
            );
        }

        return (
            <a
                key={link.name}
                href={link.href}
                className="navbar-dropdown-item"
                target={link.external ? '_blank' : '_self'}
                rel={link.external ? 'noopener noreferrer' : ''}
            >
                {link.name}
                {link.external && <ExternalLinkIcon />}
            </a>
        );
    });

    const getActiveCategory = () => {
        for (const [category, links] of Object.entries(categories)) {
            if (links.some((link) => link.name === currentPage)) {
                return category; // Match the active category based on the link name
            }
        }
        return null; // Return null if no category matches
    };
    

    const activeCategory = getActiveCategory();

    // Global variable to store the currently opened presentation window
    let presentationWindow = null;

    const openPres = async (presFile) => {
        // Check if a presentation window is already open and not closed
        if (presWindowRef.current && !presWindowRef.current.closed) {
            presWindowRef.current.focus();
            return;
        }
    
        // Determine available screen dimensions
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;
    
        // Check for ultrawide (32:9 aspect ratio or similar)
        const isUltrawide = screenWidth / screenHeight > 2;
    
        // Calculate dimensions
        let width = Math.round(screenWidth * 0.71);
        let height = Math.round(width * (9 / 16));
    
        if (height > screenHeight) {
            height = screenHeight;
            width = Math.round(height * (16 / 9));
        }
    
        let left, top;
    
        if (isUltrawide) {
            // Calculate a centered virtual 16:9 screen in the middle of the 32:9 monitor
            const virtualWidth = screenHeight * (16 / 9); // Virtual 16:9 width based on screen height
            const virtualLeft = (screenWidth - virtualWidth) / 2; // Centered 16:9 viewport
    
            // Align to the left side of the virtual 16:9 screen
            left = Math.round(virtualLeft); // Align left of the virtual viewport
            width = Math.round(virtualWidth * 0.71); // 70% of virtual 16:9 width
            height = Math.round(width * (9 / 16)); // Maintain 16:9 aspect ratio
        } else {
            // Standard positioning for non-ultrawide screens
            left = window.screen.availLeft !== undefined ? window.screen.availLeft : 0;
        }
    
        top = screenHeight - height; // Align to bottom
    
        // Open the presentation window
        presWindowRef.current = window.open(
            presFile,
            '_blank',
            `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
        );
    
        if (presWindowRef.current) {
            try {
                presWindowRef.current.moveTo(left, top);
                presWindowRef.current.resizeTo(width, height);
            } catch (e) {
                console.warn('Window positioning not fully supported in this browser.');
            }
    
            presWindowRef.current.focus();
            setIsPresOpen(true);
    
            // Listen for window close event
            presWindowRef.current.onbeforeunload = () => {
                setIsPresOpen(false);
                presWindowRef.current = null;
            };
        } else {
            alert('Failed to open presentation window. Please allow pop-ups for this site.');
        }
    };
    

    const handleLaunchPresentation = () => {
        const userId = localStorage.getItem('userId');
        const userToken = localStorage.getItem('userToken');
        const presUrl = `https://ariaslife.com/temp/agent_tools/presentation/pres_setup.html?a=${userToken}&b=${userId}`;
        openPres(presUrl);
    };
    

    const openPhone = (phoneFile) => {
        // Check if a phone window is already open and not closed
        if (phoneWindowRef.current && !phoneWindowRef.current.closed) {
            phoneWindowRef.current.focus();
            return;
        }
    
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;
    
        // Set width to half of the screen width
        const width = Math.round(screenWidth / 2);
    
        // Full height
        const height = screenHeight;
    
        // Position the window on the right half of the screen
        const left = screenWidth - width; // Start from the middle of the screen
        const top = 0; // Align to the top of the screen
    
        phoneWindowRef.current = window.open(
            phoneFile,
            '_blank',
            `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
        );
    
        if (phoneWindowRef.current) {
            setIsPhoneOpen(true);
            phoneWindowRef.current.focus();
    
            // Listen for window close event
            phoneWindowRef.current.onbeforeunload = () => {
                setIsPhoneOpen(false);
                phoneWindowRef.current = null;
            };
        } else {
            alert('Failed to open phone scripts window. Please allow pop-ups for this site.');
        }
    };
    

    useEffect(() => {
        document.body.classList.toggle('dark-mode', darkMode);
    }, [darkMode]);

    useEffect(() => {
        const agnName = localStorage.getItem('agnName');
        if (agnName) {
            const nameParts = agnName.split(' ');
            const firstName = nameParts[1];
            const hour = new Date().getHours();

            let timeGreeting;
            if (hour < 12) {
                timeGreeting = 'Good morning';
            } else if (hour < 18) {
                timeGreeting = 'Good afternoon';
            } else {
                timeGreeting = 'Good evening';
            }

            setGreeting(`${timeGreeting}, ${firstName}`);
        }
    }, []);

    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');


    
    const toggleDarkMode = () => {
        setDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem('theme', newMode ? 'dark' : 'light');
            document.body.classList.toggle('dark-mode', newMode);
            return newMode;
        });
    };

    const handleLogout = async () => {
        const userId = localStorage.getItem('userId');
        const userToken = localStorage.getItem('userToken');
    
        try {
            const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, userToken }),
            });
    
            const data = await response.json();
    
            if (data.success) {
                console.log('Logout successful. Clearing local storage.');
            } else {
                console.warn('Logout failed:', data.message);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        } finally {
            // Clear local storage regardless of success or failure
            localStorage.removeItem('agnName');
            localStorage.removeItem('userRole');
            localStorage.removeItem('userId');
            localStorage.removeItem('userToken');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('profpic');
            localStorage.removeItem('mgaRgaData');
            localStorage.removeItem('screenName');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('agency');
            
            // Navigate to login page
            navigate('/login');
        }
    };
    

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    const handleOutsideClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (menuOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [menuOpen]);

    if (!userRole) {
        return (
            <Navbar className="navbar-sticky" collapseOnSelect expand="md">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} width="45" height="45" alt="Logo" />
                    </Navbar.Brand>
                </Container>
            </Navbar>
        );
    }

    return (
        <>
        <div className="nav-container">
            <Navbar className="navbar-sticky" collapseOnSelect expand="md">
                    {/* Navbar Brand */}
                    <Navbar.Brand href="/" className="navbar-brand">
                        <img src={logo} width="45" height="45" alt="Logo" />
                    </Navbar.Brand>
    
                    {/* Hamburger menu icon for mobile */}
                    <div className="hamburger" onClick={toggleMenu}>
                        <FaBars />
                    </div>
    
                    {/* Dropdown Categories */}
                    <div className="dropdown-categories">
    {Object.entries(categories).map(([category, links]) => (
        <div
            className={`dropdown ${
                activeCategory === category ? 'active-category' : ''
            }`}
            key={category}
        >
            <div className="dropdown-header">{category}</div>
            <div className="dropdown-content">
<div className="dropdown-content">{renderLinks(links)}</div>

            </div>
        </div>
    ))}
</div>
<div className="navbar-tools">
    <button
        className={`navbar-tool-button navbar-tool-button-orange ${isPhoneOpen ? 'active' : ''}`}
        onClick={() => openPhone('https://ariaslife.com/temp/agent_tools/phone_scripts/phone_scripts.html')}
    >
        Phone Scripts
    </button>

    <span className="navbar-divider">|</span>

    <button
        className={`navbar-tool-button navbar-tool-button-green ${isPresOpen ? 'active' : ''}`}
        onClick={handleLaunchPresentation}
    >
        Launch Presentation
    </button>
    <span className="navbar-divider">|</span>

</div>

{/* Agent Sites Dropdown */}
<div className="dropdown agent-sites-dropdown">
    <div className="dropdown-header">Agent Sites</div>
    <div className="dropdown-content">
        <a
            href="https://login.ailife.com/ImpactMobile/"
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-dropdown-item"
        >
            Impact Mobile
        </a>
        <a
            href="https://login.ailife.com/ImpactPortal/"
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-dropdown-item"
        >
            Impact AWS
        </a>
        <a
            href="https://payeeweb.ailicm.globelifeinc.com/payeewebv2/login?nextPathname=%2F"
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-dropdown-item"
        >
            ICM
        </a>
        <a
            href="https://thekeefersuccess.com/WinnersCircle/agent_tools/presentation/option_builder_solo.html"
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-dropdown-item"
        >
            Option Builder
        </a>
    </div>
</div>


<div
    className="account-icon"
    onMouseEnter={() => setAccountMenuOpen(true)} // Open on hover
    onMouseLeave={() => setAccountMenuOpen(false)} // Close on hover out
>
    {userProfilePic ? (
        <img src={userProfilePic} alt="Account" className="profile-pic" />
    ) : (
        <FaUserCircle className="default-profile-icon" />
    )}


    {accountMenuOpen && ( // Only render the dropdown if `accountMenuOpen` is true
        <div className={`account-menu ${accountMenuOpen ? 'open' : ''}`}>
    <div className="account-menu-item" onClick={() => navigate('/account')}>
        Account
    </div>
    <div className="account-menu-item" onClick={handleLogout}>
        Logout
    </div>
</div>

    )}


</div>
{/*}
<button style={{border: 'none', backgroundColor: 'transparent'}}
className='presentation-button'
onClick={handleLaunchPresentation} >
<svg
        fill="#266792"
        height="40px"
        width="40px"
        viewBox="-9 -9 78.00 78.00"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        stroke="#266792"
        strokeWidth="0.0006000000000000001"
        style={{ marginLeft: '8px' }} // Add spacing between the icon and SVG
    >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.36"></g>
        <g id="SVGRepo_iconCarrier">
            <g>
                <path d="M60,4H31V1c0-0.553-0.447-1-1-1s-1,0.447-1,1v3H0v42h27.586L15.293,58.293c-0.391,0.391-0.391,1.023,0,1.414 C15.488,59.902,15.744,60,16,60s0.512-0.098,0.707-0.293L29,47.414V57c0,0.553,0.447,1,1,1s1-0.447,1-1v-9.586l12.293,12.293 C43.488,59.902,43.744,60,44,60s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L32.414,46H60V4z M58,44H2V6h56V44z"></path>
                <path d="M11,27.122V28c0,0.553,0.447,1,1,1s1-0.447,1-1v-0.882c1.091-0.222,2.086-0.801,2.818-1.668 c0.611-0.723,0.894-1.647,0.794-2.604c-0.103-0.978-0.606-1.886-1.383-2.489l-5.23-4.068c-0.35-0.272-0.576-0.681-0.622-1.12 c-0.042-0.409,0.075-0.801,0.332-1.104C10.28,13.388,11.115,13,12,13c1.654,0,3,1.346,3,3c0,0.553,0.447,1,1,1s1-0.447,1-1 c0-2.414-1.721-4.434-4-4.899v-0.878c0-0.553-0.447-1-1-1s-1,0.447-1,1v0.882c-1.091,0.222-2.086,0.8-2.818,1.667 c-0.611,0.723-0.893,1.646-0.794,2.603c0.102,0.979,0.605,1.887,1.383,2.492l5.23,4.068c0.35,0.271,0.576,0.68,0.622,1.119 c0.042,0.409-0.075,0.802-0.332,1.105c-0.571,0.676-1.406,1.063-2.291,1.063c-1.654,0-3-1.346-3-3c0-0.553-0.447-1-1-1 s-1,0.447-1,1C7,24.637,8.721,26.657,11,27.122z"></path>
                <path d="M39,20h14v4H42c-0.553,0-1,0.447-1,1s0.447,1,1,1h11v4h-4c-0.553,0-1,0.447-1,1s0.447,1,1,1h4v5h-7c-0.553,0-1,0.447-1,1 s0.447,1,1,1h7v2c0,0.553,0.447,1,1,1s1-0.447,1-1V10c0-0.553-0.447-1-1-1s-1,0.447-1,1v2h-7c-0.553,0-1,0.447-1,1s0.447,1,1,1h7v4 H39c-0.553,0-1,0.447-1,1S38.447,20,39,20z"></path>
                <path d="M16,38H8c-0.553,0-1,0.447-1,1s0.447,1,1,1h8c0.553,0,1-0.447,1-1S16.553,38,16,38z"></path>
                <path d="M20.29,38.29C20.109,38.479,20,38.729,20,39c0,0.27,0.109,0.52,0.29,0.7C20.479,39.89,20.729,40,21,40 c0.26,0,0.52-0.11,0.71-0.29C21.89,39.52,22,39.26,22,39s-0.11-0.521-0.29-0.71C21.34,37.92,20.66,37.92,20.29,38.29z"></path>
                <path d="M21,33h-3c-0.553,0-1,0.447-1,1s0.447,1,1,1h3c0.553,0,1-0.447,1-1S21.553,33,21,33z"></path>
                <path d="M8,35h2c0.553,0,1-0.447,1-1s-0.447-1-1-1H8c-0.553,0-1,0.447-1,1S7.447,35,8,35z"></path>
                <path d="M13.29,33.29C13.109,33.479,13,33.74,13,34c0,0.27,0.109,0.52,0.29,0.71C13.479,34.89,13.74,35,14,35s0.52-0.11,0.71-0.29 C14.89,34.52,15,34.26,15,34s-0.11-0.521-0.29-0.71C14.33,32.92,13.67,32.92,13.29,33.29z"></path>
            </g>
        </g>
    </svg>
</button>

<button
    style={{
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    }}
    className='phone-button'
    onClick={() => openPhone('https://ariaslife.com/temp/agent_tools/phone_scripts/phone_scripts.html')}
>
    <img
        src={phoneIcon}
        alt="Phone"
        style={{ height: '37px', width: '37px' }}
    />
</button>

    
{/* Hamburger menu content */}
{menuOpen && (
    <div className="hamburger-menu" ref={menuRef}>
        {Object.entries(categories).map(([category, links]) => (
            <div className="hamburger-category" key={category}>
                <span>{category}</span>
                {links.map((link) => (
                    <a
                        key={link.name}
                        href={link.href}
                        target={link.external ? '_blank' : '_self'}
                        rel={link.external ? 'noopener noreferrer' : ''}
                        className="navbar-dropdown-item"
                    >
                        {link.name}
                    </a>
                ))}
            </div>
        ))}
        {/* Agent Sites Category */}
        <div className="hamburger-category">
            <span>Agent Sites</span>
            <a
                href="https://login.ailife.com/ImpactMobile/"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-dropdown-item"
            >
                Impact Mobile
            </a>
            <a
                href="https://login.ailife.com/ImpactPortal/"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-dropdown-item"
            >
                Impact AWS
            </a>
            <a
                href="https://payeeweb.ailicm.globelifeinc.com/payeewebv2/login?nextPathname=%2F"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-dropdown-item"
            >
                ICM
            </a>
            <a
                href="https://thekeefersuccess.com/WinnersCircle/agent_tools/presentation/option_builder_solo.html"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-dropdown-item"
            >
                Option Builder
            </a>
        </div>
        {/* Account and Logout Options */}
        <div className="hamburger-account">
            <a href="/account" className="navbar-dropdown-item">
                Account
            </a>
            <div className="navbar-dropdown-item" onClick={handleLogout}>
                Logout
            </div>
        </div>
    </div>
)}

            </Navbar>

        </div>
        </>
    );
    
}

export default CustomNavbar;
