// src/App.js

import React, { useState, useEffect } from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard/Dashboard';
import Production from './components/Production';
import Login from './components/Auth/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Codes from './components/Codes/Codes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AgentSetupPage from './components/Auth/AgentSetup';
import More from './components/MORE/More';
import RGaGrowth from './components/RGAGrowth/RGaGrowth';
import Register from './components/Auth/Register';
import Account from './components/Account/Account';
import Report from './components/Report/Report';
import Compliance from './components/Recruiting/Compliance';
import Tutorials from './components/Resources/Tutorials';
import Resources from './components/Resources/Resources';
import AdminForms from './components/Admin/AdminForms';
import Calculate from './components/Calculate/Calculate';
import Pipeline from './components/Recruiting/Pipeline';
import Applicants from './components/Recruiting/Recruits/Applicants';
import Verify from './components/Verification/Verify';
import { GlobalDataProvider } from './contexts/GlobalDataContext';
import { initGA, usePageTracking } from './components/utils/analytics'; // Adjust the import path as necessary
import AdminLogin from './components/Auth/AdminLogin'; // Import AdminLogin component
import AdminNavbar from './components/Org/AdminNavbar'; // Import AdminNavbar component
import AdminVerify from './components/Org/AdminVerify';
import AdminDashboard from './components/Org/AdminDashboard';
import Release from './components/Release/Release'; // Import Release component
import { ProgressProvider } from './components/Release/ProgressContext'; // Import ProgressProvider component
import AlpSpreadsheet from './components/Report/NoTabs';
import Presentation from './components/Presentation/Presentation'; // Import Presentation component
import logo from './img/globe1.png';
import Scorecard from './components/Scorecard/Scorecard';
import Leaderboard from './components/Leaderboard/Leaderboard';
import { SnowfallProvider } from './contexts/SnowfallContext';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

function App() {
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState("");
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        // Initialize Google Analytics on app mount
        initGA();
    }, []);

    useEffect(() => {
        const handleStartLoading = () => setIsLoading(true);
        const handleStopLoading = () => setIsLoading(false);
    
        const simulateLoading = () => {
            handleStartLoading();
            setTimeout(() => {
                handleStopLoading();
            }, 500); // Adjust timeout as necessary
        };
    
        simulateLoading();
    }, [location]);
    
    const validateToken = () => {
        // Exclude admin routes and adminlogin from JWT validation
        if (location.pathname.startsWith('/admin/') || location.pathname === '/adminlogin') {
            return true; // Skip validation for admin routes and adminlogin
        }
    
        const token = localStorage.getItem("userToken");
    
        if (!token) {
            if (location.pathname !== '/login') {
                navigate("/login"); // Redirect to login only if not already on the login page
            }
            return false;
        }
    
        try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Current time in seconds
            if (decoded.exp < currentTime) {
                // Token expired
                localStorage.removeItem("userToken");
                if (location.pathname !== '/login') {
                    navigate("/login");
                }
                return false;
            }
            return true;
        } catch (error) {
            console.error("Invalid token:", error);
            localStorage.removeItem("userToken");
            localStorage.removeItem('userRole');
            localStorage.removeItem('userId');
            localStorage.removeItem('agnName');
            localStorage.removeItem('agnNum');
            localStorage.removeItem('email');
            localStorage.removeItem('phone');
            localStorage.removeItem('profpic');
            localStorage.removeItem('userInfo');
    
            // Store in sessionStorage
            sessionStorage.removeItem('userToken');
            sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('agnName');
            sessionStorage.removeItem('agnNum');
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('phone');
            sessionStorage.removeItem('profpic');
            sessionStorage.removeItem('userInfo');

      
            if (location.pathname !== '/login') {
                navigate("/login");
            }
            return false;
        }
    };
    

    useEffect(() => {
        // Validate token on app load or route change, excluding admin routes
        validateToken();
    }, [location]);
    
    usePageTracking(); // Track page views

    useEffect(() => {
        // Function to set the current page based on the route
        const routeToPageName = (path) => {
            switch(path) {
                case '/login': return 'LoginPage';
                case '/register': return 'Register';
                case '/agent-setup': return 'Agent Setup';
                case '/account': return 'Account'; 
                case '/': return 'Dashboard';
                case '/production': return 'Production';
                case '/codes': return 'Codes & VIPs';
                case '/more': return 'M.O.R.E.';
                case '/rga-growth': return 'Growth';
                case '/compliance': return 'Compliance'; 
                case '/reports': return 'Report Activity'; 
                case '/tutorials': return 'Tutorials'; 
                case '/admin-forms': return 'Admin Forms'; 
                case '/calculate': return 'Calculate'; 
                case '/pipeline': return 'Pipeline'; 
                case '/applicants': return 'Applicants'; 
                case '/resources': return 'Resources'; 
                case '/verify': return 'Verify'; 
                case '/release': return 'Release'; 
                case '/alp-spreadsheet': return 'ALP Spreadsheet'; 
                case '/presentation': return 'Presentation'; 
                case '/scorecard': return 'Scorecard'; 
                case '/leaderboard': return 'Leaderboards';
                default: return '';
            }
        };

        setCurrentPage(routeToPageName(location.pathname)); // Set page name based on current route
    }, [location]);

    const isAuthorizedForAdminForms = () => {
        const agnName = localStorage.getItem('agnName');
        const authorizedNames = ['VANBIBBER KYLE A', 'KEEFER JAMES E'];
        return authorizedNames.includes(agnName);
    };
    
// Function to check if user has Admin role
const isAdmin = () => {
    const userRole = localStorage.getItem('userRole');
    return userRole === 'Admin';
};

const shouldShowAdminNavbar = location.pathname.startsWith('/admin/') && isAdmin();
const shouldShowMainNavbar = !location.pathname.startsWith('/adminlogin') && !shouldShowAdminNavbar;

return (
    <div className="app-container">
    <GlobalDataProvider>
        <SnowfallProvider>
        <ProgressProvider>
            {isLoading && (
                <div className="globe-loading-overlay">
                    <img src={logo} alt="Loading" className="globe-loading-animation" />
                </div>
            )}
            {shouldShowMainNavbar && <Navbar currentPage={currentPage} />}
            {shouldShowAdminNavbar && <AdminNavbar currentPage={currentPage} />}
            <Routes>
            <Route
        path="/login"
        element={
            localStorage.getItem("userToken") && localStorage.getItem("userId") ? (
                <Navigate to="/" replace />
            ) : (
                <Login />
            )
        }
    />                <Route path="/adminlogin" element={<AdminLogin />} />
                <Route path="/register" element={<Register />} />
                <Route path="/agent-setup" element={<AgentSetupPage />} />
                <Route path="/" element={<ProtectedRoute element={Dashboard} />} />
                <Route path="/production" element={<ProtectedRoute element={Production} />} />
                <Route path="/codes" element={<ProtectedRoute element={Codes} />} />
                <Route path="/more" element={<ProtectedRoute element={More} />} />
                <Route path="/rga-growth" element={<ProtectedRoute element={RGaGrowth} />} />
                <Route path="/compliance" element={<ProtectedRoute element={Compliance} />} />
                <Route path="/account" element={<ProtectedRoute element={Account} />} />
                <Route path="/reports" element={<ProtectedRoute element={Report} />} />
                <Route path="/tutorials" element={<ProtectedRoute element={Tutorials} />} />
                <Route path="/calculate" element={<ProtectedRoute element={Calculate} />} />
                <Route path="/pipeline" element={<ProtectedRoute element={Pipeline} />} />
                <Route path="/applicants" element={<ProtectedRoute element={Applicants} />} />
                <Route path="/resources" element={<ProtectedRoute element={Resources} />} />
                <Route path="/verify" element={<ProtectedRoute element={Verify} />} />
                <Route path="/release" element={<ProtectedRoute element={Release} />} />
                <Route path="/alp-spreadsheet" element={<ProtectedRoute element={AlpSpreadsheet} />} />
                <Route path="/presentation" element={<ProtectedRoute element={Presentation} />} />
                <Route path="/scorecard" element={<ProtectedRoute element={Scorecard} />} />
                <Route path="/leaderboard" element={<ProtectedRoute element={Leaderboard} />} />

                {/* Protected Admin Route */}
                <Route
                    path="/admin/verify"
                    element={isAdmin() ? <AdminVerify /> : <Navigate to="/adminlogin" replace />}
                />
                                <Route
                    path="/admin/release"
                    element={isAdmin() ? <Release /> : <Navigate to="/adminlogin" replace />}
                />
                <Route
                    path="/admin/"
                    element={isAdmin() ? <AdminDashboard /> : <Navigate to="/adminlogin" replace />}
                />

                <Route
                    path="/admin-forms"
                    element={isAuthorizedForAdminForms() ? <AdminForms /> : <Navigate to="/login" replace />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </ProgressProvider>
        </SnowfallProvider>
    </GlobalDataProvider>
    </div>
);

}
export default App;
