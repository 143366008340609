import React from 'react';
import './LeaderboardPlaceholder.css'; // Add styles for the placeholder

const LeaderboardPlaceholder = ({ title }) => {
    return (
        <div>
        <div className="leaderboard-placeholder">
            <div className="leaderboard-placeholder-rows">
                {[...Array(5)].map((_, index) => (
                    <div className="placeholder-row" key={index}>
                        <div className="placeholder-avatar"></div>
                        <div className="placeholder-details">
                            <div className="placeholder-line short"></div>
                            <div className="placeholder-line"></div>
                        </div>
                        <div className="placeholder-alp"></div>
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
};

export default LeaderboardPlaceholder;
