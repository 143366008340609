import React, { useState, useEffect } from "react";
import MoreFilters from "./MoreFilters";
import MoreDataTable from "./MoreDataTable";
import MoreLeaderboard from "./MoreLeaderboard";
import MoreCards from "./MoreCards";
import AmoreForm from "./ReportMore";
import axios from "axios";
import FunnelGraph from "./FunnelGraphComponent";
import TopThreeLeaderboard from "./TopThreeLeaderboard";
import MoreReportingStatus from "./MoreReportingStatus";
import MainButtons from "../utils/MainButtons";
const FilterableMore = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [recruitingWeeks, setRecruitingWeeks] = useState([]);
  const [recruitingMonths, setRecruitingMonths] = useState([]);
  const [recruitingYears, setRecruitingYears] = useState([]);
  const [allMGAs, setAllMGAs] = useState([]);
  const [allRGAs, setAllRGAs] = useState([]);
  const [allLegacies, setAllLegacies] = useState([]);
  const [allTrees, setAllTrees] = useState([]);
    const [availableMGAs, setAvailableMGAs] = useState([]);
    const [availableRGAs, setAvailableRGAs] = useState([]);
    const [availableTrees, setAvailableTrees] = useState([]);
    const agnName = localStorage.getItem("agnName") || "";
    const [leaderboardData, setLeaderboardData] = useState([]);

    const [filters, setFilters] = useState({
      periodType: "week",
      periodValue: [], // Allow multiple selections
      MGA: "",
      RGA: "",
      Legacy: "",
      Tree: "",
    });
    
    const [formData, setFormData] = useState({
      MGA: "",
      MORE_Date: "",
      External_Sets: 0,
      External_Shows: 0,
      Internal_Sets: 0,
      Internal_Shows: 0,
      Personal_Sets: 0,
      Personal_Shows: 0,
      Total_Set: 0,
      Total_Show: 0,
      Group_Invite: 0,
      Finals_Set: 0,
      Finals_Show: 0,
      Non_PR_Hires: 0,
      PR_Hires: 0,
      Total_Hires: 0,
    });
  useEffect(() => {
    document.title = "M.O.R.E.";
  }, []);

console.log ('filtered Data', filteredData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://ariaslogin-4a95935f6093.herokuapp.com/api/all-amore-data"
        );
        if (response.data.success && response.data.data) {
          const rawData = response.data.data;
          setData(rawData);
  
          // Extract unique weeks, months, and years
          const uniqueWeeks = [...new Set(rawData.map((item) => item.MORE_Date))]
            .sort((a, b) => new Date(b) - new Date(a))
            .map((date) => ({
              value: date,
              label: new Date(date).toLocaleDateString(),
            }));
  
            const uniqueMonths = [
              ...new Set(
                rawData.map((item) => {
                  const date = new Date(item.MORE_Date);
                  const year = date.getFullYear();
                  const month = date.getMonth(); // 0-based index for month
                  return `${year}-${(month + 1).toString().padStart(2, "0")}`; // Ensure month is formatted correctly
                })
              ),
            ]
              .sort((a, b) => {
                const [yearA, monthA] = a.split("-");
                const [yearB, monthB] = b.split("-");
                return new Date(`${yearB}-${monthB}-01`) - new Date(`${yearA}-${monthA}-01`); // Sort descending
              })
              .map((month) => {
                const [year, monthIndex] = month.split("-");
                const date = new Date(year, parseInt(monthIndex, 10) - 1, 1); // Use numeric month, 0-based index
                return {
                  value: month,
                  label: date.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  }),
                };
              });
            
              console.log("Generated uniqueMonths:", uniqueMonths);

  
          const uniqueYears = [
            ...new Set(rawData.map((item) => new Date(item.MORE_Date).getFullYear())),
          ]
            .sort((a, b) => b - a)
            .map((year) => ({ value: year.toString(), label: year.toString() }));
  
          const uniqueMGAs = [...new Set(rawData.map((item) => item.MGA))].sort();
          const uniqueRGAs = [...new Set(rawData.map((item) => item.RGA))].sort();
          const uniqueLegacies = [...new Set(rawData.map((item) => item.Legacy))].sort();
          const uniqueTrees = [...new Set(rawData.map((item) => item.Tree))].sort();
  
          setRecruitingWeeks(uniqueWeeks);
          setRecruitingMonths(uniqueMonths);
          setRecruitingYears(uniqueYears);
          setAllMGAs(uniqueMGAs);
          setAllRGAs(uniqueRGAs);
          setAllLegacies(uniqueLegacies);
          setAllTrees(uniqueTrees);
  
          // Set default filters
          const newestWeek = uniqueWeeks[0]?.value || "";
  
          // Determine default filter based on agnName priority: Tree > RGA > MGA
          if (uniqueTrees.includes(agnName)) {
            console.log(`agnName "${agnName}" found in Tree. Setting default Tree.`);
            setFilters((prev) => ({
              ...prev,
              periodValue: newestWeek,
              Tree: agnName,
              MGA: "", // Clear MGA if Tree is set
              RGA: "", // Clear RGA if Tree is set
            }));
          } else if (uniqueRGAs.includes(agnName)) {
            console.log(`agnName "${agnName}" found in RGA. Setting default RGA.`);
            setFilters((prev) => ({
              ...prev,
              periodValue: newestWeek,
              RGA: agnName,
              MGA: "", // Clear MGA if RGA is set
              Tree: "", // Clear Tree if RGA is set
            }));
          } else {
            console.log(`Setting default MGA to "${agnName}".`);
            setFilters((prev) => ({
              ...prev,
              periodValue: newestWeek,
              MGA: agnName,
            }));
          }
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
  
    fetchData();
  }, []);
  
  const aggregateDataByMGA = (data, filters) => {
    if (!data || data.length === 0) return [];
  
    const { periodType } = filters;
  
    const aggregatedData = data.reduce((acc, item) => {
      // Group by MGA
      if (!acc[item.MGA]) {
        acc[item.MGA] = {
          MGA: item.MGA,
          Total_Hires: 0,
          PR_Hires: 0,
          // Add any additional fields you need
        };
      }
  
      // Sum up Total_Hires and PR_Hires
      acc[item.MGA].Total_Hires += item.Total_Hires || 0;
      acc[item.MGA].PR_Hires += item.PR_Hires || 0;
  
      return acc;
    }, {});
  
    // Convert the result back to an array
    return Object.values(aggregatedData);
  };
  
  const aggregateLeaderboardData = (data) => {
    return data.reduce((acc, item) => {
      const existingEntry = acc.find((entry) => entry.MGA === item.MGA);
  
      if (existingEntry) {
        existingEntry.Total_Hires += item.Total_Hires || 0;
        existingEntry.PR_Hires += item.PR_Hires || 0;
      } else {
        acc.push({
          MGA: item.MGA,
          Total_Hires: item.Total_Hires || 0,
          PR_Hires: item.PR_Hires || 0,
        });
      }
  
      return acc;
    }, []);
  };
  
  useEffect(() => {
    const filtered = data
      .filter((item) => {
        const { periodType, periodValue, MGA, RGA, Tree } = filters;
  
        const periodMatch = (() => {
          if (Array.isArray(periodValue) && periodValue.length > 0) {
            return periodValue.some((value) => {
              if (periodType === "week") return item.MORE_Date === value;
              if (periodType === "month") {
                const monthValue = value.split("-");
                const [year, month] = monthValue;
                const itemDate = new Date(item.MORE_Date);
                return (
                  itemDate.getFullYear().toString() === year &&
                  (itemDate.getMonth() + 1).toString().padStart(2, "0") === month
                );
              }
              if (periodType === "year") {
                return new Date(item.MORE_Date).getFullYear().toString() === value;
              }
              return false;
            });
          }
          return true;
        })();
  
        const mgaMatch = !MGA || item.MGA === MGA;
        const rgaMatch =
          !RGA ||
          item.RGA === RGA || // Normal RGA match
          item.MGA === RGA; // Include rows where RGA matches MGA
        const treeMatch = !Tree || item.Tree === Tree;
  
        return periodMatch && mgaMatch && rgaMatch && treeMatch;
      })
      // Add the current `formData` at the top of the filtered data
      .concat(formData);
  
    setFilteredData(filtered);
  
    // Aggregate leaderboard data based on the selected period type
    const leaderboardFiltered =
      filters.periodType === "month" || filters.periodType === "year"
        ? aggregateLeaderboardData(filtered)
        : filtered;
  
    setLeaderboardData(leaderboardFiltered);
  
    // Update available options based on filtered data
    const uniqueMGAs = [...new Set(filtered.map((item) => item.MGA))].sort();
    const uniqueRGAs = [...new Set(filtered.map((item) => item.RGA))].sort();
    const uniqueTrees = [...new Set(filtered.map((item) => item.Tree))].sort();
  
    setAvailableMGAs(uniqueMGAs);
    setAvailableRGAs(uniqueRGAs);
    setAvailableTrees(uniqueTrees);
  }, [filters, data, formData]);
  
  
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };
  const handleButtonClick = () => {
    window.location.href = "https://ariaslife.com/MORE";
  };
  const getFunnelData = (data) => {
    if (!data || data.length === 0) return [];

    const totals = {
      Total_Set: 0,
      Total_Show: 0,
      Group_Invite: 0,
      Finals_Set: 0,
      Finals_Show: 0,
      Total_Hires: 0,
    };

    data.forEach((item) => {
      totals.Total_Set += item.Total_Set || 0;
      totals.Total_Show += item.Total_Show || 0;
      totals.Group_Invite += item.Group_Invite || 0;
      totals.Finals_Set += item.Finals_Set || 0;
      totals.Finals_Show += item.Finals_Show || 0;
      totals.Total_Hires += item.Total_Hires || 0;
    });

    return [
      { index: 0, stage: "Total Set", value: totals.Total_Set },
      { index: 1, stage: "Total Show", value: totals.Total_Show },
      { index: 2, stage: "Group Invite", value: totals.Group_Invite },
      { index: 3, stage: "Finals Set", value: totals.Finals_Set },
      { index: 4, stage: "Finals Show", value: totals.Finals_Show },
      { index: 5, stage: "Total Hires", value: totals.Total_Hires },
      { index: 6, stage: "Total Hires", value: totals.Total_Hires },
    ];
  };

  return (
    <div >
      <div className="large-app-container">

      <AmoreForm formData={formData} setFormData={setFormData} />

        <MoreFilters
          onFilterChange={handleFilterChange}
          recruitingWeeks={recruitingWeeks}
          recruitingMonths={recruitingMonths}
          recruitingYears={recruitingYears}
          allMGAs={availableMGAs} // Use dynamically updated options
          allRGAs={availableRGAs} // Use dynamically updated options
          allTrees={availableTrees} // Use dynamically updated options
          defaultMGA={filters.MGA}
          defaultRGA={filters.RGA}
          defaultTree={filters.Tree}
        />
       

        <div className="more-header-with-toggle-and-filters">
          <h5>Activity & Stats</h5>
        </div>

        <MoreCards moreData={filteredData} />

        <div className="more-header-with-toggle-and-filters">
          <h5>Leaderboard</h5>
        </div>

        <TopThreeLeaderboard data={leaderboardData} allAmoreData={data} />

        {filters.periodType === "week" && (
          <MoreReportingStatus 
            amoreData={filteredData} 
            filters={filters} 
          />
        )}

        <MoreDataTable moreData={filteredData} />
      </div>
    </div>
  );
};

export default FilterableMore;
