import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";
import TrophyCaseModal from "../Leaderboards/TrophyCaseModal";
const LeaderboardChild = ({ title, rows, isF6, experienceFilter, netOrGross, isFullScreen, setExpandedLeaderboard }) => {
    const [selectedAgent, setSelectedAgent] = useState(null); // Track selected agent for modal
    const [showModal, setShowModal] = useState(false); // Track modal visibility

    const openTrophyModal = (row) => {
        if (!row || typeof row !== "object") {
            console.error("Invalid row data received in openTrophyModal:", row);
            return;
        }
    
        const agentFullName = row.LagnName || row.reformattedName || row.agent || row.name; // Handle multiple possible field names
        if (!agentFullName) {
            console.error("No agent name found in row data:", row);
            return;
        }
    
        console.log("Opening modal for agent:", agentFullName); // Debugging log
        setSelectedAgent(agentFullName);
        setShowModal(true);
    };
    
    
    const closeTrophyModal = () => {
        setShowModal(false);
        setSelectedAgent(null);
    };

    const formatCurrency = (value) => {
        if (!value) return "N/A";
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };
    const toggleFullScreen = () => {
        setExpandedLeaderboard(isFullScreen ? null : title);
    };
    const getTitleColor = (title) => {
        if (title.includes("SAs")) return "rgb(178, 82, 113)";
        if (title.includes(" GAs")) return "rgb(237, 114, 47)";
        if (title.includes("MGAs")) return "rgb(104, 182, 117)";
        if (title.includes("RGAs")) return "#00558c";
        return "inherit"; // Default color
    };
    const getDynamicTitle = () => {
        if (title === "Top Producers") {
            if (experienceFilter === "rookie") return "Top Rookies";
            if (experienceFilter === "veteran") return "Top Veterans";
            return "Top Producers"; // Default for all
        }

        if (title === "Top SAs") {
            return experienceFilter === "rookie" ? "Rookie SAs" 
                 : experienceFilter === "veteran" ? "Veteran SAs" 
                 : "Top SAs";
        }

        if (title === "Top GAs") {
            return experienceFilter === "rookie" ? "Rookie GAs" 
                 : experienceFilter === "veteran" ? "Veteran GAs" 
                 : "Top GAs";
        }

        if (title === "Top MGAs") {
            return experienceFilter === "rookie" ? "Rookie MGAs" 
                 : experienceFilter === "veteran" ? "Veteran MGAs" 
                 : "Top MGAs";
        }

        if (title === "Top RGAs") {
            return  "Top RGAs";
        }

        return title; // Keep the original title for other tables
    };

    const getALPColumnName = () => {
        if (title === "Top Producers") {
            return netOrGross === "net" ? "Net ALP" : "Gross ALP"; // Exclude F6 for Top Producers
        }
        if (isF6) {
            return netOrGross === "net" ? "F6 Net ALP" : "F6 Gross ALP";
        }
        return netOrGross === "net" ? "Net ALP" : "Gross ALP";
    };
    
    // Determine the column name based on the title
    const columnName = title === 'SA Data' ? 'SA' : title === 'GA Data' ? 'GA' : 'Agent';

    return (
        <div className={`leaderboard-child ${isFullScreen ? "full-screen" : ""}`}>
            {/* Full Screen Button */}
           <div className={`leaderboard-child ${title === "Top Producers" ? "all-data" : ""}`}>


                {rows.length > 0 ? (
                    <table className="all-leaderboard-table">
                        <thead>
                            <tr>
                            <th style={{ color: getTitleColor(getDynamicTitle())}}>
                                {getDynamicTitle()}
                            </th>                                <th>{columnName}</th>
                                <th>{getALPColumnName()} {/* Dynamic ALP/F6 ALP label */}
                                <button className="fullscreen-button" onClick={toggleFullScreen}>
                    <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} size="lg" />
                </button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
        <tr key={index} onClick={() => openTrophyModal(row)} style={{ cursor: "pointer" }}>
                                    <td>{index + 1}</td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        >
                                            {row.profpic ? (
                                                <img
                                                    src={row.profpic}
                                                    alt={row.reformattedName || "Agent Profile"}
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        borderRadius: "50%",
                                                        backgroundColor: "#ddd",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginBottom: "5px",
                                                        fontWeight: "bold",
                                                        color: "#666",
                                                    }}
                                                >
                                                    {row.reformattedName?.charAt(0).toUpperCase() || "N"}
                                                </div>
                                            )}
                                            <strong
                                                style={{
                                                    fontSize: "14px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {row.reformattedName}
                                            </strong>
                                            {title !== "Top MGAs" && title !== "Top RGAs" && (
                                                <small
    style={{
        color: "grey",
        fontSize: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }}
>
    {/* Adjust MGA_NAME logic for MGA and RGA rows */}
    {row.clname === "MGA" || row.clname === "RGA"
        ? row.LagnName?.split(" ")[0] || "N/A"
        : row.MGA_NAME?.split(" ")[0] || "N/A"}
</small>

                                            )}
                                            {title === "Top Producers" && (
                                                    <span
                                                        className="user-role-badge"
                                                        style={{
                                                            backgroundColor:
                                                                row.clname === 'AGT'
                                                                    ? 'lightgrey'
                                                                    : row.clname === 'SA'
                                                                    ? 'rgb(178, 82, 113)'
                                                                    : row.clname === 'GA'
                                                                    ? 'rgb(237, 114, 47)'
                                                                    : row.clname === 'MGA'
                                                                    ? 'rgb(104, 182, 117)'
                                                                    : row.clname === 'RGA'
                                                                    ? '#00558c'
                                                                    : 'transparent',
                                                            border: `2px solid ${
                                                                row.clname === 'AGT'
                                                                    ? 'grey'
                                                                    : row.clname === 'SA'
                                                                    ? 'rgb(138, 62, 93)'
                                                                    : row.clname === 'GA'
                                                                    ? 'rgb(197, 94, 37)'
                                                                    : row.clname === 'MGA'
                                                                    ? 'rgb(84, 152, 97)'
                                                                    : row.clname === 'RGA'
                                                                    ? '#004372'
                                                                    : 'transparent'
                                                            }`,
                                                            padding: "2px 4px",
                                                            borderRadius: "4px",
                                                            fontSize: "10px",
                                                        }}
                                                    >
                                                        {row.clname}
                                                    </span>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ padding: "0", width: "0", height: "0" }}></td>
                                    <td>{formatCurrency(row.alpValue)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No data available for this leaderboard.</p>
                )}
            </div>
            {showModal && <TrophyCaseModal agentName={selectedAgent} onClose={closeTrophyModal} />}

        </div>
    );
};

export default LeaderboardChild;


