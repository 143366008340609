import React, { useEffect, useState } from "react";
import axios from "axios";

const AdobeSign = ({ onSigned }) => {
    const [isSigned, setIsSigned] = useState(false);
  const [step, setStep] = useState(1); // Track user progress
  const userId = localStorage.getItem("userId");

  // Function to log interactions
  const logInteraction = (message, data = {}) => {
    const timestamp = new Date().toISOString();
    const logEntry = { timestamp, message, data };

    console.log(`[LOG] ${timestamp} - ${message}`, data);

    // Store logs in local storage for debugging
    const logs = JSON.parse(localStorage.getItem("adobeSignLogs")) || [];
    logs.push(logEntry);
    localStorage.setItem("adobeSignLogs", JSON.stringify(logs));
  };

  useEffect(() => {
    if (userId) {
      axios
        .get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/check-signature?userId=${userId}`)
        .then((res) => {
          if (res.data.signed) {
            setIsSigned(true);
            onSigned(); // Notify Dashboard
          }
        })
        .catch((err) => console.error("Error fetching sign status:", err));
    }
  }, [userId, onSigned]);

  const handleConfirmSignature = async () => {
    setIsSigned(true);

    axios
      .post("https://ariaslogin-4a95935f6093.herokuapp.com/api/save-signature", {
        userId,
        signed: true,
      })
      .then(() => {
        alert("Your signature has been recorded.");
        onSigned(); // Notify Dashboard to remove AdobeSign
      })
      .catch((err) => console.error("Error saving signature:", err));
  };

  // Function to move to the next step
  const nextStep = () => {
    if (step < 7) {
      setStep(step + 1);
      logInteraction(`Moved to Step ${step + 1}`);
    }
  };

  // Function to move to the previous step
  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
      logInteraction(`Moved to Step ${step - 1}`);
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h5>Agent Addendum - Sales Leads</h5>

      {/* Step Navigation */}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" }}>
        <button
        className="insured-button"
          onClick={prevStep}
          disabled={step === 1}
          style={{
            padding: "8px 16px",
            backgroundColor: step === 1 ? "#ccc" : "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: step === 1 ? "not-allowed" : "pointer",
          }}
        >
          Back
        </button>

        <p><strong>Step {step} of 7</strong></p>

        <button
        className="insured-button"
          onClick={nextStep}
          disabled={step === 7}
          style={{
            padding: "8px 16px",
            backgroundColor: step === 7 ? "#ccc" : "#28a745",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: step === 7 ? "not-allowed" : "pointer",
          }}
        >
          Next
        </button>
      </div>

      {/* Step-by-step instructions */}
      {step === 1 && <p>📜 <strong>Click Continue on the Agreement.</strong> Read the agreement below.</p>}
      {step === 2 && <p>✍️ <strong>Sign your name on page 4.</strong></p>}
      {step === 3 && <p>📩 <strong>After signing, a popup will appear at the bottom. Click "Click to Sign" and enter your email.</strong></p>}
      {step === 4 && <p>✅ <strong>Select your MGA on page 5.</strong></p>}
      {step === 5 && <p>📧 <strong>Go to your email, find the Adobe Sign email with the subject: "Please confirm your signature on Agent Addendum", and confirm your email address.</strong></p>}
      {step === 6 && <p>🖼️ <strong>Wait for the confirmation image to appear.</strong></p>}
      {step === 7 && <p>🔘 <strong>Click the "I Have Signed" button below.</strong></p>}

      {/* iFrame for Adobe Sign */}
      {step >= 1 && (
        <iframe
          src="https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDdOvDnbhJag2M-TWNWoOldtHuOGj77ZF7nxP_AYOl_-uisLV-5-1ykK1n-ZDW-srw*&hosted=false"
          width="100%"
          height="600px"
          frameBorder="0"
          style={{ border: "0", minHeight: "500px", minWidth: "600px" }}
          onLoad={() => logInteraction("AdobeSign iframe loaded")}
        ></iframe>
      )}

      {/* I Have Signed Button (only enabled at Step 7) */}
      {step === 7 && (
        <button
        className="insured-button"
          onClick={handleConfirmSignature}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: isSigned ? "#6c757d" : "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: isSigned ? "default" : "pointer",
          }}
          disabled={isSigned}
        >
          {isSigned ? "✅ Signature Recorded" : "I Have Signed"}
        </button>
      )}
    </div>
  );
};

export default AdobeSign;
