import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import './Cards.css';
import axios from 'axios';
import AgentCountBadge from '../utils/AgentCountBadge';

const ActivityCards = ({ startDate, endDate, view, selectedAgent, setDateRange, isDateRangeHovered }) => {
    const [hoveredCard, setHoveredCard] = useState(null); // Track hovered card

    const [processedData, setProcessedData] = useState({
        alp: 0,
        calls: 0,
        appts: 0,
        sits: 0,
        sales: 0,
        refs: 0,
        callsToSet: 0,
        callsToSit: 0,
        showRatio: 0,
        closeRatio: 0,
        alpPerSit: 0,
        alpPerSale: 0,
        refsPerSit: 0,
        refSitPercent: 0,
        refClosePercent: 0,
        alpPerRefSale: 0,
        refAlp: 0,
        alpPerRefCol: 0,
        totalSales: 0,
        refAppt: 0,
        refSit: 0,
        refSale: 0,
    });

    const [displayData, setDisplayData] = useState({
        alp: 0,
        calls: 0,
        appts: 0,
        sits: 0,
        sales: 0,
        refs: 0,
        callsToSet: 0,
        callsToSit: 0,
        showRatio: 0,
        closeRatio: 0,
        alpPerSit: 0,
        alpPerSale: 0,
        refsPerSit: 0,
        refSitPercent: 0,
        refClosePercent: 0,
        alpPerRefSale: 0,
        refAlp: 0,
        alpPerRefCol: 0,
        totalSales: 0,
        refAppt: 0,
        refSit: 0,
        refSale: 0,
    });

    const [showAverage, setShowAverage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [teamData, setTeamData] = useState([]);
    const [agentCount, setAgentCount] = useState(0); // State for agent count
    const [agentNames, setAgentNames] = useState([]); // State for agent names
    const [agentData, setAgentData] = useState([]); // State for agent data

    const handleToggle = (event) => {
        setShowAverage(event.target.id === "average");
    };

    useEffect(() => {
        if (view === 'team') {
            fetchTeamData();
        } else if (view === 'myview') {
            fetchMyViewData();
        } else {
            fetchData();
        }
    }, [showAverage, startDate, endDate, view, selectedAgent]); // Add selectedAgent here

    useEffect(() => {
        if (view === 'team' && teamData.length > 0) {
            fetchData(teamData);
        }
    }, [teamData, view]);

    const fetchData = async (teamNames = teamData) => {
        setIsLoading(true);
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];

        try {
            const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
            const result = await response.json();
            if (result.success) {
                processData(result.data, formattedStartDate, formattedEndDate, teamNames);
            } else {
                console.error('Error fetching data:', result.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMyViewData = async () => {
        setIsLoading(true);
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
    
        // Get agentName from localStorage if not passed in props
        const agentName = selectedAgent || localStorage.getItem('agnName'); // Use localStorage if selectedAgent is undefined
    
        try {
            const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
            const result = await response.json();
            if (result.success) {
                processData(result.data, formattedStartDate, formattedEndDate, agentName);
            } else {
                console.error('Error fetching data:', result.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    

    const fetchTeamData = async () => {
        setIsLoading(true);
        try {
            const userId = localStorage.getItem('userId'); // Get userId from localStorage
            const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/rgaHierarchy', {
                params: { userId }
            });
            const data = response.data;
            if (data.success && Array.isArray(data.data)) {
                const teamNames = data.data.flatMap(item => {
                    const agtString = item.AGTs;
                    const names = [];

                    // Extract the first part
                    const firstPart = agtString.split('; ')[0];
                    names.push(firstPart.split(' - ')[0]);

                    // Extract other parts
                    const otherParts = agtString.split('-- ');
                    otherParts.forEach(part => {
                        const name = part.split(' - ')[0];
                        names.push(name);
                    });

                    return names;
                });
                setTeamData(teamNames); // Set team names
            } else {
                console.error('Failed to load team data');
            }
        } catch (err) {
            console.error('Error fetching team data:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const animateValue = (key, start, end, duration) => {
        if (start === end) return;
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            setDisplayData(prevData => ({
                ...prevData,
                [key]: Math.floor(progress * (end - start) + start)
            }));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    };

    const processData = (data, formattedStartDate, formattedEndDate, agentName) => {
        let sum = {
            alp: 0,
            calls: 0,
            appts: 0,
            sits: 0,
            sales: 0,
            refs: 0,
            refAppt: 0,
            refSit: 0,
            refSale: 0, // Add this
        };
    
        const filteredData = data.filter(item => item.reportDate >= formattedStartDate && item.reportDate <= formattedEndDate);
    
        const dataToProcess = view === 'team'
            ? filteredData.filter(item => teamData.includes(item.agent))
            : view === 'myview'
            ? filteredData.filter(item => item.agent === agentName)
            : filteredData;
    
        setAgentCount(new Set(dataToProcess.map(item => item.agent)).size);
        setAgentNames([...new Set(dataToProcess.map(item => item.agent))]);
        setAgentData(dataToProcess);
    
        dataToProcess.forEach(item => {
            sum.alp += parseFloat(item.alp || 0);
            sum.calls += parseFloat(item.calls || 0);
            sum.appts += parseFloat(item.appts || 0);
            sum.sits += parseFloat(item.sits || 0);
            sum.sales += parseFloat(item.sales || 0);
            sum.refs += parseFloat(item.refs || 0);
            sum.refAppt += parseFloat(item.refAppt || 0);
            sum.refSit += parseFloat(item.refSit || 0);
            sum.refSale += parseFloat(item.refSale || 0); // Add this
        });
    
        let result;
        if (showAverage && dataToProcess.length > 0) {
            result = {
                alp: sum.alp / dataToProcess.length,
                calls: sum.calls / dataToProcess.length,
                appts: sum.appts / dataToProcess.length,
                sits: sum.sits / dataToProcess.length,
                sales: sum.sales / dataToProcess.length,
                refs: sum.refs / dataToProcess.length,
                refAppt: sum.refAppt / dataToProcess.length,
                refSit: sum.refSit / dataToProcess.length,
                refSale: sum.refSale / dataToProcess.length, // Add this
            };
        } else {
            result = sum;
        }
    
        const calculatedStats = calculateStatistics(dataToProcess);
    
        setProcessedData({
            ...result,
            ...calculatedStats
        });
    
        Object.keys(result).forEach(key => {
            animateValue(key, displayData[key], result[key], 500);
        });
        Object.keys(calculatedStats).forEach(key => {
            animateValue(key, displayData[key], calculatedStats[key], 500);
        });
    };
    
    const calculateStatistics = (data) => {
        const totalRefsCollected = data.reduce((sum, item) => sum + (parseFloat(item.refs) || 0), 0);
        const totalRefALP = data.reduce((sum, item) => sum + (parseFloat(item.refAlp) || 0), 0);
        const totalReferralSales = data.reduce((sum, item) => sum + (parseFloat(item.refSale) || 0), 0);
        const totalReferralAppts = data.reduce((sum, item) => sum + (parseFloat(item.refAppt) || 0), 0);
        const totalReferralSits = data.reduce((sum, item) => sum + (parseFloat(item.refSit) || 0), 0);
        const alpPerRefCol = totalRefsCollected > 0 ? totalRefALP / totalRefsCollected : 0;
        const calculatedRefALP = totalReferralSales > 0 ? totalRefALP / totalReferralSales : 0;
    
        return {
            callsToSet: calculateCallsToSet(data),
            callsToSit: calculateCallsToSit(data),
            showRatio: calculateShowRatio(data),
            closeRatio: calculateCloseRatio(data),
            alpPerSit: calculateALPPerSit(data),
            alpPerSale: calculateALPPerSale(data),
            refsPerSit: calculateRefsPerSit(data),
            refSitPercent: calculateRefSitPercent(data),
            refClosePercent: calculateRefClosePercent(data),
            alpPerRefSale: calculatedRefALP,
            refAlp: totalRefALP,
            alpPerRefCol: alpPerRefCol,
            totalSales: totalReferralSales,
            refAppt: totalReferralAppts, // Add this
            refSit: totalReferralSits,   // Add this
            refSale: totalReferralSales, // Add this
        };
    };
    
    
    const calculateCallsToSet = (data) => {
        const totalCalls = data.reduce((sum, row) => sum + parseFloat(row.calls || 0), 0);
        const totalAppointments = data.reduce((sum, row) => sum + parseFloat(row.appts || 0), 0);
        return totalAppointments > 0 ? Math.round(totalCalls / totalAppointments) : 0;
    };

    const calculateCallsToSit = (data) => {
        const totalCalls = data.reduce((sum, row) => sum + parseFloat(row.calls || 0), 0);
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        return totalSits > 0 ? Math.round(totalCalls / totalSits) : 0;
    };

    const calculateShowRatio = (data) => {
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        const totalAppointments = data.reduce((sum, row) => sum + parseFloat(row.appts || 0), 0);
        return totalAppointments > 0 ? ((totalSits / totalAppointments) * 100).toFixed(2) : '0.00';
    };
    
    const calculateCloseRatio = (data) => {
        const totalSales = data.reduce((sum, row) => sum + parseFloat(row.sales || 0), 0);
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        return totalSits > 0 ? ((totalSales / totalSits) * 100).toFixed(0) : '0.00';
    };
    

    const calculateALPPerSit = (data) => {
        const totalALP = data.reduce((sum, row) => sum + parseFloat(row.alp || 0), 0);
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        const alpPerSit = totalSits > 0 ? totalALP / totalSits : 0;
        return alpPerSit;
    };

    const calculateALPPerSale = (data) => {
        const totalALP = data.reduce((sum, row) => sum + parseFloat(row.alp || 0), 0);
        const totalSales = data.reduce((sum, row) => sum + parseFloat(row.sales || 0), 0);
        const alpPerSale = totalSales > 0 ? totalALP / totalSales : 0;
        return alpPerSale;
    };

    const calculateRefsPerSit = (data) => {
        const totalSits = data.reduce((sum, row) => sum + parseFloat(row.sits || 0), 0);
        const totalRefsCollected = data.reduce((sum, row) => sum + parseFloat(row.refs || 0), 0);
        return totalSits > 0 ? (totalRefsCollected / totalSits).toFixed(2) : '0.00';
    };
    
    
    const calculateRefSitPercent = (data) => {
        const totalRefSits = data.reduce((sum, row) => sum + parseFloat(row.refSit || 0), 0);
        const totalRefsCollected = data.reduce((sum, row) => sum + parseFloat(row.refs || 0), 0);
        return totalRefsCollected > 0 ? ((totalRefSits / totalRefsCollected) * 100).toFixed(2) : '0.00';
    };
    
    const calculateRefClosePercent = (data) => {
        const totalReferralSits = data.reduce((sum, row) => sum + parseFloat(row.refSit || 0), 0);
        const totalReferralSales = data.reduce((sum, row) => sum + parseFloat(row.refSale || 0), 0);
        return totalReferralSits > 0 ? ((totalReferralSales / totalReferralSits) * 100).toFixed(2) : '0.00';
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }).format(value);
    };

    const formatDateRange = (start, end) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedStartDate = start.toLocaleDateString('en-US', options);
        const formattedEndDate = end.toLocaleDateString('en-US', options);
        return `${formattedStartDate} - ${formattedEndDate}`;
    };
    const dateRange = formatDateRange(startDate, endDate);

    const circleRadius = 15.915; // Radius of the circle in the SVG
    const circumference = 2 * Math.PI * circleRadius;
    
    const calculatePercentage = (part, total) =>
      total ? ((part / total) * 100).toFixed(0) : "0";
    
    const calculateStrokeDasharray = (percentage) => {
      const filled = (percentage / 100) * circumference;
      return `${filled} ${circumference}`;
    };
    
    const getDonutColor = (key) => {
        const colors = {
            callsToSet: "#00558c",      // Blue
            callsToSit: "#00558c",      // Blue
            showRatio: "#ff9800",       // Orange
            closeRatio: "rgb(178, 82, 113)", // Pink
            alpPerSale: "#4caf50",      // Green
            alpPerSit: "#4caf50",       // Green
            refsPerSit: "#00558c",      // Blue
            refSitPercent: "#ff9800",   // Orange
            refClosePercent: "rgb(178, 82, 113)", // Pink
            alpPerRefCol: "#4caf50",    // Green
            alpPerRefSale: "#4caf50",   // Green
        };
        return colors[key] || "#4caf50"; // Default to Green if key not found
    };
    
    

    return (
<>
  {/* Top Row: ALP and Ref ALP remain unchanged */}
  <div className="header-row">
    <h4 style={{cursor: 'pointer'}}  onClick={() => window.location.href = '/reports'}
    >Activity</h4>
    {(view === 'team' || view === 'agency') && (
      <AgentCountBadge 
        count={agentCount} 
        agentNames={agentNames} 
        agentData={agentData} 
        dateRange={dateRange}
        onDateRangeChange={(newRange) => setDateRange(newRange)}
        startDate={startDate}
        endDate={endDate}
      />
    )}
  </div>
  <div class="activity-wrapper">

  <div className="top-cards-row">
    {[
      { label: 'ALP', key: 'alp', format: v => `$${v.toLocaleString()}` },
      { label: 'Ref ALP', key: 'refAlp', format: formatCurrency }
    ].map(({ label, key, format }) => (
      <div className="activity-card" key={key}>
        <h5 className="activity-card-title">{label}</h5>
        {isLoading ? (
          <TailSpin height="20" width="20" color="gray" ariaLabel="loading" />
        ) : (
          <p className="activity-card-text">{format(displayData[key])}</p>
        )}
      </div>
    ))}
  </div>

  {/* New Columns Container */}
  <div className="activity-columns-container">
    {/* Left Column (under ALP) */}
    <div className="activity-column left-activity-column">
      <div className="activity-row">
        {[
          {
            label: 'Calls',
            key: 'calls',
            format: v => v.toLocaleString(),
            hoverMetric1: 'callsToSet',
            hoverMetric2: 'callsToSit',
            max1: 100,
            max2: 250,
            label1: 'Call/Set',
            label2: 'Call/Sit'
          },
          {
            label: 'Appts',
            key: 'appts',
            format: v => v.toLocaleString(),
            metric: 'showRatio',
            max: 100,
            isPercentage: true,
            customLabel: 'Show'
          }
        ].map((item) => {
          // Destructure properties for clarity.
          const {
            label,
            key,
            format,
            metric,
            hoverMetric1,
            hoverMetric2,
            max,
            max1,
            max2,
            isPercentage,
            label1,
            label2,
            customLabel
          } = item;
          const value = displayData[key];
          const percentage = metric ? calculatePercentage(displayData[metric], max) : 0;

          let hoverValue, hoverPercentage, displayLabel;
          if (hoverMetric1 && hoverMetric2) {
            const isHovered = hoveredCard === key;
            const hoverKey = isHovered ? hoverMetric2 : hoverMetric1;
            const hoverMax = isHovered ? max2 : max1;
            hoverValue = displayData[hoverKey];
            hoverPercentage = calculatePercentage(hoverValue, hoverMax);
            displayLabel = isHovered ? label2 : label1;
          } else {
            displayLabel = customLabel || label;
          }

          return (
            <div 
              className="activity-card" 
              key={key} 
              onMouseEnter={() => setHoveredCard(key)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <h5 className="activity-card-title">{label}</h5>
              {(metric || hoverMetric1) && (
                <div className="activity-donut-container">
                  <svg viewBox="0 0 36 36" className="activity-donut-chart">
                    <circle className="donut-ring" cx="18" cy="18" r="15.915" fill="transparent" stroke="#e0e0e0" strokeWidth="3" />
                    <circle className="donut-segment" cx="18" cy="18" r="15.915" fill="transparent" stroke={getDonutColor(metric || hoverMetric1)} strokeWidth="3"
                      strokeDasharray={calculateStrokeDasharray(hoverMetric1 ? hoverPercentage : percentage)}
                      strokeDashoffset="0"
                    />
                    <text x="18" y="18" textAnchor="middle" fontSize="7" fill="#333">
                      {hoverMetric1 ? (hoverMetric1 && (typeof item.formatMetric === 'function' ? item.formatMetric(hoverValue) : hoverValue))
                        : (isPercentage ? `${percentage}%` : displayData[metric])}
                    </text>
                    <text x="18" y="25" textAnchor="middle" fontSize="5" fill="#666">
                      {displayLabel}
                    </text>
                  </svg>
                </div>
              )}
              {isLoading ? (
                <TailSpin height="20" width="20" color="gray" ariaLabel="loading" />
              ) : (
                <p className="activity-card-text">{format(value)}</p>
              )}
            </div>
          );
        })}
      </div>
      <div className="activity-row">
        {[
          {
            label: 'Sits',
            key: 'sits',
            format: v => v.toLocaleString(),
            metric: 'closeRatio',
            max: 100,
            isPercentage: true,
            customLabel: 'Close'
          },
          {
            label: 'Sales',
            key: 'sales',
            format: v => v.toLocaleString(),
            hoverMetric1: 'alpPerSit',
            hoverMetric2: 'alpPerSale',
            max1: 600,
            max2: 1800,
            formatMetric: v => `$${v.toFixed(0)}`,
            label1: 'ALP/Sit',
            label2: 'ALP/Sale'
          }
        ].map((item) => {
          const {
            label,
            key,
            format,
            metric,
            hoverMetric1,
            hoverMetric2,
            max,
            max1,
            max2,
            isPercentage,
            label1,
            label2,
            formatMetric,
            customLabel
          } = item;
          const value = displayData[key];
          const percentage = metric ? calculatePercentage(displayData[metric], max) : 0;

          let hoverValue, hoverPercentage, displayLabel;
          if (hoverMetric1 && hoverMetric2) {
            const isHovered = hoveredCard === key;
            const hoverKey = isHovered ? hoverMetric2 : hoverMetric1;
            const hoverMax = isHovered ? max2 : max1;
            hoverValue = displayData[hoverKey];
            hoverPercentage = calculatePercentage(hoverValue, hoverMax);
            displayLabel = isHovered ? label2 : label1;
          } else {
            displayLabel = customLabel || label;
          }

          return (
            <div 
              className="activity-card" 
              key={key} 
              onMouseEnter={() => setHoveredCard(key)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <h5 className="activity-card-title">{label}</h5>
              {(metric || hoverMetric1) && (
                <div className="activity-donut-container">
                  <svg viewBox="0 0 36 36" className="activity-donut-chart">
                    <circle className="donut-ring" cx="18" cy="18" r="15.915" fill="transparent" stroke="#e0e0e0" strokeWidth="3" />
                    <circle className="donut-segment" cx="18" cy="18" r="15.915" fill="transparent" stroke={getDonutColor(metric || hoverMetric1)} strokeWidth="3"
                      strokeDasharray={calculateStrokeDasharray(hoverMetric1 ? hoverPercentage : percentage)}
                      strokeDashoffset="0"
                    />
                    <text x="18" y="18" textAnchor="middle" fontSize="7" fill="#333">
                      {hoverMetric1 ? (formatMetric ? formatMetric(hoverValue) : hoverValue)
                        : (isPercentage ? `${percentage}%` : displayData[metric])}
                    </text>
                    <text x="18" y="25" textAnchor="middle" fontSize="5" fill="#666">
                      {displayLabel}
                    </text>
                  </svg>
                </div>
              )}
              {isLoading ? (
                <TailSpin height="20" width="20" color="gray" ariaLabel="loading" />
              ) : (
                <p className="activity-card-text">{format(value)}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
<div className="activity-divider"></div>
    {/* Right Column (under Ref ALP) */}
    <div className="activity-column right-activity-column">
      <div className="activity-row">
        {[
          {
            label: 'Refs',
            key: 'refs',
            format: v => v.toLocaleString(),
            metric: 'refsPerSit',
            max: 15,
            isPercentage: false,
            customLabel: 'Refs/Sit'
          },
          {
            label: 'Ref Appts',
            key: 'refAppt',
            format: v => v.toLocaleString(),
            metric: 'refSitPercent',
            max: 100,
            isPercentage: true,
            metricLabel: 'Sit %'
          }
        ].map((item) => {
          const {
            label,
            key,
            format,
            metric,
            max,
            isPercentage,
            customLabel,
            metricLabel,
            hoverMetric1,
            hoverMetric2,
            max1,
            max2
          } = item;
          const value = displayData[key];
          const percentage = metric ? calculatePercentage(displayData[metric], max) : 0;

          let hoverValue, hoverPercentage, displayLabel;
          if (hoverMetric1 && hoverMetric2) {
            const isHovered = hoveredCard === key;
            const hoverKey = isHovered ? hoverMetric2 : hoverMetric1;
            const hoverMax = isHovered ? max2 : max1;
            hoverValue = displayData[hoverKey];
            hoverPercentage = calculatePercentage(hoverValue, hoverMax);
            displayLabel = isHovered ? metricLabel && hoverMetric2 : metricLabel;
          } else {
            displayLabel = customLabel || label;
          }

          return (
            <div 
              className="activity-card" 
              key={key}
              onMouseEnter={() => setHoveredCard(key)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <h5 className="activity-card-title">{label}</h5>
              {(metric || hoverMetric1) && (
                <div className="activity-donut-container">
                  <svg viewBox="0 0 36 36" className="activity-donut-chart">
                    <circle className="donut-ring" cx="18" cy="18" r="15.915" fill="transparent" stroke="#e0e0e0" strokeWidth="3" />
                    <circle className="donut-segment" cx="18" cy="18" r="15.915" fill="transparent" stroke={getDonutColor(metric || hoverMetric1)} strokeWidth="3"
                      strokeDasharray={calculateStrokeDasharray(hoverMetric1 ? hoverPercentage : percentage)}
                      strokeDashoffset="0"
                    />
                    <text x="18" y="18" textAnchor="middle" fontSize="7" fill="#333">
                      {hoverMetric1 ? (hoverMetric1 && hoverValue) : (isPercentage ? `${percentage}%` : displayData[metric])}
                    </text>
                    <text x="18" y="25" textAnchor="middle" fontSize="5" fill="#666">
                      {displayLabel}
                    </text>
                  </svg>
                </div>
              )}
              {isLoading ? (
                <TailSpin height="20" width="20" color="gray" ariaLabel="loading" />
              ) : (
                <p className="activity-card-text">{format(value)}</p>
              )}
            </div>
          );
        })}
      </div>
      <div className="activity-row">
        {[
          {
            label: 'Ref Sits',
            key: 'refSit',
            format: v => v.toLocaleString(),
            metric: 'refClosePercent',
            max: 100,
            isPercentage: true,
            metricLabel: 'Close'
          },
          {
            label: 'Ref Sales',
            key: 'refSale',
            format: v => v.toLocaleString(),
            hoverMetric1: 'alpPerRefSale',
            hoverMetric2: 'alpPerRefCol',
            max1: 2400,
            max2: 50,
            formatMetric: formatCurrency,
            label1: 'ALP/Sale',
            label2: 'ALP/Ref'
          }
        ].map((item) => {
          const {
            label,
            key,
            format,
            metric,
            hoverMetric1,
            hoverMetric2,
            max,
            max1,
            max2,
            isPercentage,
            label1,
            label2,
            formatMetric,
            metricLabel,
            customLabel
          } = item;
          const value = displayData[key];
          const percentage = metric ? calculatePercentage(displayData[metric], max) : 0;
          let hoverValue, hoverPercentage, displayLabel;
          if (hoverMetric1 && hoverMetric2) {
            const isHovered = hoveredCard === key;
            const hoverKey = isHovered ? hoverMetric2 : hoverMetric1;
            const hoverMax = isHovered ? max2 : max1;
            hoverValue = displayData[hoverKey];
            hoverPercentage = calculatePercentage(hoverValue, hoverMax);
            displayLabel = isHovered ? label2 : label1;
          } else {
            displayLabel = customLabel || metricLabel || label;
          }
          return (
            <div 
              className="activity-card" 
              key={key}
              onMouseEnter={() => setHoveredCard(key)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <h5 className="activity-card-title">{label}</h5>
              {(metric || hoverMetric1) && (
                <div className="activity-donut-container">
                  <svg viewBox="0 0 36 36" className="activity-donut-chart">
                    <circle className="donut-ring" cx="18" cy="18" r="15.915" fill="transparent" stroke="#e0e0e0" strokeWidth="3" />
                    <circle className="donut-segment" cx="18" cy="18" r="15.915" fill="transparent" stroke={getDonutColor(metric || hoverMetric1)} strokeWidth="3"
                      strokeDasharray={calculateStrokeDasharray(hoverMetric1 ? hoverPercentage : percentage)}
                      strokeDashoffset="0"
                    />
                    <text x="18" y="18" textAnchor="middle" fontSize="7" fill="#333">
                      {hoverMetric1 ? (formatMetric ? formatMetric(hoverValue) : hoverValue)
                        : (isPercentage ? `${percentage}%` : displayData[metric])}
                    </text>
                    <text x="18" y="25" textAnchor="middle" fontSize="5" fill="#666">
                      {displayLabel}
                    </text>
                  </svg>
                </div>
              )}
              {isLoading ? (
                <TailSpin height="20" width="20" color="gray" ariaLabel="loading" />
              ) : (
                <p className="activity-card-text">{format(value)}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
    </div>
  </div>
</>

    );
    
};

export default ActivityCards;

