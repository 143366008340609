import React, { useState } from 'react';
import VerificationForm from './VerificationForm';
import VerifyTable from './VerifyTable';
import MainButtons from '../utils/MainButtons';

const Verify = () => {
    const [view, setView] = useState('form'); // Set default view
    return (
        <div>
            <div className="large-app-container">
                <h5 className="page-header">Verify</h5>
                <div className="tabs" style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
                <input
                        type="radio"
                        id="form"
                        name="view"
                        value="form"
                        checked={view === 'form'}
                        onChange={() => setView('form')}
                        className="tab-input"
                    />
                    <label htmlFor="form" className={`tab-label ${view === 'form' ? 'active' : ''}`}>
                        Verification Form
                    </label>
                    <input
                    
                        type="radio"
                        id="table"
                        name="view"
                        value="table"
                        checked={view === 'table'}
                        onChange={() => setView('table')}
                        className="tab-input"
                    />
                    <label  htmlFor="table" className={`tab-label ${view === 'table' ? 'active' : ''}`}>
                        Table
                    </label>


                </div>

                {view === 'table' && <VerifyTable />}
                {view === 'form' && <VerificationForm />}
            </div>
        </div>
    );
};

export default Verify;
