import React, { useState, useEffect } from "react";

const DailyActivityReport = () => {
  const [selectedRange, setSelectedRange] = useState("week"); // week, month, ytd
  const [dateRows, setDateRows] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [activityData, setActivityData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editedRows, setEditedRows] = useState({});

  useEffect(() => {
    generateDateRows();
  }, [selectedRange, currentDate]);

  useEffect(() => {
    if (dateRows.length > 0) {
      fetchData();
    }
  }, [dateRows]);

  const generateDateRows = () => {
    let start, end, dates = [];
    const today = new Date();
    const viewingDate = new Date(currentDate);

    if (selectedRange === "week") {
        // Determine the Monday of the current week
        const dayOfWeek = viewingDate.getDay(); // 0 = Sunday, 6 = Saturday
        const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Move backward to Monday
        start = new Date(viewingDate);
        start.setDate(viewingDate.getDate() + mondayOffset);
        start.setHours(0, 0, 0, 0); // Normalize time

        // Set end date to the following Sunday
        end = new Date(start);
        end.setDate(start.getDate() + 6); // Always go to Sunday

        console.log(`📆 Week Start (Monday): ${start.toISOString().split("T")[0]}`);
        console.log(`📆 Week End (Sunday): ${end.toISOString().split("T")[0]}`);

        // Populate the week range from Monday to Sunday
        for (let i = 0; i <= 6; i++) {
            const date = new Date(start);
            date.setDate(start.getDate() + i);
            dates.push(date.toISOString().split("T")[0]);
        }
    } else if (selectedRange === "month") {
        // Ensure month start and end use correct date formatting (no timezone shift)
        start = new Date(viewingDate.getFullYear(), viewingDate.getMonth(), 1);
        end = new Date(viewingDate.getFullYear(), viewingDate.getMonth() + 1, 0); // Last day of month

        console.log(`📆 Month Start: ${start.toISOString().split("T")[0]}`);
        console.log(`📆 Month End: ${end.toISOString().split("T")[0]}`);

        // Populate month range from first to last day
        let current = new Date(start);
        while (current <= end) {
            dates.push(current.toISOString().split("T")[0]); // Ensure format is YYYY-MM-DD
            current.setDate(current.getDate() + 1);
        }
    } else if (selectedRange === "ytd") {
        if (viewingDate.getFullYear() === today.getFullYear()) {
            start = new Date(viewingDate.getFullYear(), 0, 1);
            end = today;
        } else {
            start = new Date(viewingDate.getFullYear(), 0, 1);
            end = new Date(viewingDate.getFullYear(), 11, 31);
        }
        while (start <= end) {
            dates.push(start.toISOString().split("T")[0]);
            start.setDate(start.getDate() + 1);
        }
    }

    setDateRows(dates.reverse()); // Reverse to show newest dates first
};

const generateDropdownOptions = () => {
  const options = [];
  const today = new Date();

  if (selectedRange === "week") {
      // Generate weeks from the current week backward
      let start = new Date(today);
      start.setDate(start.getDate() - (start.getDay() === 0 ? 6 : start.getDay() - 1)); // Set to Monday

      while (start >= new Date(today.getFullYear(), 0, 1)) { // Stop at first week of the year
          let end = new Date(start);
          end.setDate(start.getDate() + 6); // Sunday

          options.push({
              value: start.toISOString(),
              label: `${start.toLocaleDateString("en-US", { month: "numeric", day: "numeric", year: "2-digit" })} - 
                      ${end.toLocaleDateString("en-US", { month: "numeric", day: "numeric", year: "2-digit" })}`
          });

          start.setDate(start.getDate() - 7); // Move back one week
      }
  } else if (selectedRange === "month") {
      // Generate months from current month backward
      let start = new Date(today.getFullYear(), today.getMonth(), 1);

      while (start.getFullYear() >= today.getFullYear() - 1) { // Stop at Jan of last year
          let end = new Date(start.getFullYear(), start.getMonth() + 1, 0); // Last day of the month

          options.push({
              value: start.toISOString(),
              label: `${start.toLocaleDateString("en-US", { month: "long", year: "numeric" })}`
          });

          start.setMonth(start.getMonth() - 1); // Move back one month
      }
  } else if (selectedRange === "ytd") {
      // Generate years from current year backward
      let year = today.getFullYear();

      while (year >= today.getFullYear() - 4) { // Last 5 years
          options.push({
              value: new Date(year, 0, 1).toISOString(),
              label: `${year}`
          });
          year--; // Move back one year
      }
  }

  return options;
};



const handleChange = (event, reportDate, field) => {
  const value = event.target.value;
  console.log(`✏️ Editing ${field} for reportDate: ${reportDate}, New Value: ${value}`);

  setEditedRows((prev) => ({
    ...prev,
    [reportDate]: {
      ...prev[reportDate],
      reportDate,
      [field]: value === "" ? "" : parseFloat(value) || 0, // Retain decimals
    },
  }));
};

  
  const handleCancel = () => {
    setEditedRows({});
  };
  
  
  const handleSubmit = async () => {
    const userId = localStorage.getItem("userId");
    if (!userId || Object.keys(editedRows).length === 0) {
        console.error("🚨 No changes to submit or missing userId.");
        return;
    }

    // Ensure each row update contains the correctly formatted reportDate
    const updatesWithDates = Object.keys(editedRows).reduce((acc, reportDate) => {
        const formattedDate = new Date(reportDate).toISOString().split("T")[0]; // Ensure it's YYYY-MM-DD
        acc[formattedDate] = { reportDate: formattedDate, ...editedRows[reportDate] };
        return acc;
    }, {});

    console.log("📤 Sending updates to backend:", updatesWithDates);

    try {
        const response = await fetch("http://localhost:3000/api/updateDailyActivity", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ userId, updates: updatesWithDates }),
        });

        const result = await response.json();
        console.log("✅ Backend Response:", result);

        if (result.success) {
            setActivityData((prev) => ({
                ...prev,
                ...editedRows,
            }));
            setEditedRows({});
        } else {
            console.error("❌ Update failed:", result.message);
        }
    } catch (error) {
        console.error("🚨 Error updating data:", error);
    }
};

const fetchData = async () => {
  if (dateRows.length === 0) return;

  setLoading(true);
  const userId = localStorage.getItem("userId");
  if (!userId) {
      console.error("User ID not found in localStorage");
      setLoading(false);
      return;
  }

  const startDate = dateRows[dateRows.length - 1]; // Oldest date
  const endDate = dateRows[0]; // Newest date

  console.log("📥 Fetching data for date range:");
  console.log(`🔍 Start Date (oldest):`, startDate);
  console.log(`🔍 End Date (newest):`, endDate);

  try {
      const response = await fetch(
          `http://localhost:3000/api/getUserActivitySummary?userId=${userId}&startDate=${startDate}&endDate=${endDate}`
      );
      const result = await response.json();

      if (!result.success || !Array.isArray(result.data)) {
          console.error("Invalid API response format:", result);
          setActivityData({});
          setLoading(false);
          return;
      }

      console.log("📊 Raw Fetched Data:", result.data);

      // Ensure data is mapped using `reportDate` as the key
      const mappedData = {};
      result.data.forEach((entry) => {
          if (entry.reportDate) {
              console.log(`🗂️ Processing fetched reportDate:`, entry.reportDate);
              const formattedDate = entry.reportDate.split("T")[0]; // Keep only YYYY-MM-DD
              console.log(`🔄 Converted reportDate to YYYY-MM-DD:`, formattedDate);
              mappedData[formattedDate] = entry;
          }
      });

      console.log("✅ Final mapped data:", mappedData);
      setActivityData(mappedData);
  } catch (error) {
      console.error("🚨 Error fetching activity data:", error);
      setActivityData({});
  }
  setLoading(false);
};


  const navigateBackward = () => {
    let newDate = new Date(currentDate);
    if (selectedRange === "week") newDate.setDate(newDate.getDate() - 7);
    else if (selectedRange === "month") newDate.setMonth(newDate.getMonth() - 1);
    else if (selectedRange === "ytd") newDate.setFullYear(newDate.getFullYear() - 1);
    setCurrentDate(newDate);
  };

  const navigateForward = () => {
    let newDate = new Date(currentDate);
    if (selectedRange === "week") newDate.setDate(newDate.getDate() + 7);
    else if (selectedRange === "month") newDate.setMonth(newDate.getMonth() + 1);
    else if (selectedRange === "ytd" && newDate.getFullYear() < new Date().getFullYear()) {
      newDate.setFullYear(newDate.getFullYear() + 1);
    }
    setCurrentDate(newDate);
  };

  const calculateTotals = () => {
    const newTotals = {
      calls: 0,
      appts: 0,
      sits: 0,
      sales: 0,
      alp: 0,
      refs: 0,
      refAppt: 0,
      refSit: 0,
      refSale: 0,
      refAlp: 0,
    };
  
    dateRows.forEach((date) => {
      const row = activityData[date] || {}; // Original fetched data
      const editedRow = editedRows[date] || {}; // Edited values
  
      newTotals.calls += editedRow.calls !== undefined ? parseFloat(editedRow.calls) || 0 : row.calls || 0;
      newTotals.appts += editedRow.appts !== undefined ? parseFloat(editedRow.appts) || 0 : row.appts || 0;
      newTotals.sits += editedRow.sits !== undefined ? parseFloat(editedRow.sits) || 0 : row.sits || 0;
      newTotals.sales += editedRow.sales !== undefined ? parseFloat(editedRow.sales) || 0 : row.sales || 0;
      newTotals.alp += editedRow.alp !== undefined ? parseFloat(editedRow.alp) || 0 : row.alp || 0;
      newTotals.refs += editedRow.refs !== undefined ? parseFloat(editedRow.refs) || 0 : row.refs || 0;
      newTotals.refAppt += editedRow.refAppt !== undefined ? parseFloat(editedRow.refAppt) || 0 : row.refAppt || 0;
      newTotals.refSit += editedRow.refSit !== undefined ? parseFloat(editedRow.refSit) || 0 : row.refSit || 0;
      newTotals.refSale += editedRow.refSale !== undefined ? parseFloat(editedRow.refSale) || 0 : row.refSale || 0;
      newTotals.refAlp += editedRow.refAlp !== undefined ? parseFloat(editedRow.refAlp) || 0 : row.refAlp || 0;
    });
  
    return newTotals;
  };
  
  // Create a state for totals and update it whenever `editedRows` or `activityData` changes
  const [totals, setTotals] = useState(calculateTotals());
  
  useEffect(() => {
    setTotals(calculateTotals());
  }, [editedRows, activityData]);
  

  return (
    <div className="daily-activity-report">

      <div className="tabs-filter-container">
    <span 
        className={selectedRange === "week" ? "selected" : "unselected"} 
        onClick={() => setSelectedRange("week")}
    >
        Week
    </span>
    <span className="separator">|</span>
    <span 
        className={selectedRange === "month" ? "selected" : "unselected"} 
        onClick={() => setSelectedRange("month")}
    >
        MTD
    </span>
    <span className="separator">|</span>
    <span 
        className={selectedRange === "ytd" ? "selected" : "unselected"} 
        onClick={() => setSelectedRange("ytd")}
    >
        YTD
    </span>
</div>

<div className="date-range-navigation-container">
    <div className="date-range-navigation">
        <button className="nav-button" onClick={navigateBackward}>{"<"}</button>

        <select 
            className="date-dropdown" 
            value={currentDate.toISOString()} 
            onChange={(e) => setCurrentDate(new Date(e.target.value))}
        >
            {generateDropdownOptions().map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>

        <button className="nav-button" onClick={navigateForward}>{">"}</button>
    </div>
</div>



      <div style={{ minHeight: "40px" }}>
  <button 
    className="insured-button" 
    onClick={handleSubmit} 
    disabled={loading}
    style={{ display: Object.keys(editedRows).length > 0 ? "inline-block" : "none" }}
  >
    <div className={`more-spinner ${loading ? "visible" : ""}`}></div>
    {!loading && "Submit Changes"}
  </button>
  <button 
    className="insured-button" 
    onClick={handleCancel} 
    style={{ display: Object.keys(editedRows).length > 0 ? "inline-block" : "none" }}
  >
    Cancel
  </button>
</div>




      {loading ? (
        <p>Loading data...</p>
      ) : (
        <div className="hier-table-container">
        <table className="agentcount-table">
        <thead>
  <tr>
    {["", "Date", "Calls", "Appts", "Sits", "Sales", "ALP", "Refs", "Ref Appts", "Ref Sits", "Ref Sales", "Ref ALP"].map((header, index) => (
      <th key={index} style={{ backgroundColor: "rgb(0, 85, 140)", minWidth: "65px" }}>
        {header}
      </th>
    ))}
  </tr>
</thead>

<tbody>
  <tr style={{ backgroundColor: 'rgb(210, 210, 210)' }}>
    <td><strong></strong></td>
    <td>Totals</td> 
    <td><strong>{totals.calls}</strong></td>
    <td><strong>{totals.appts}</strong></td>
    <td><strong>{totals.sits}</strong></td>
    <td><strong>{totals.sales}</strong></td>
    <td><strong>{totals.alp.toFixed(2)}</strong></td>
    <td><strong>{totals.refs}</strong></td>
    <td><strong>{totals.refAppt}</strong></td>
    <td><strong>{totals.refSit}</strong></td>
    <td><strong>{totals.refSale}</strong></td>
    <td><strong>{totals.refAlp.toFixed(2)}</strong></td>
  </tr>

  {dateRows.map((date, index) => {
    const row = activityData[date] || {};
    const isEditing = editedRows[date];

    // Get today's date in YYYY-MM-DD format to match data
    const today = new Date().toISOString().split("T")[0];
    const isToday = date === today; // Check if this row is today's date

    // Check if data exists for this row (i.e., it has a `reportDate`)
    const hasData = row.reportDate !== undefined;

    // Get the actual date object and shift it **back by one day**
    const adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() + 1);

    // Get the adjusted day abbreviation
    const dayAbbreviations = { 
      "Sunday": "Su", "Monday": "Mo", "Tuesday": "Tu", "Wednesday": "We", 
      "Thursday": "Th", "Friday": "Fr", "Saturday": "Sa" 
    };
    const fullDay = adjustedDate.toLocaleDateString("en-US", { weekday: "long" });
    const dayOfWeek = dayAbbreviations[fullDay] || "";

    // **🔹 Apply alternating row colors for YTD & Month views**
    const weekIndex = Math.floor(index / 7);
    const isOddWeek = weekIndex % 2 !== 0;
    const ytdBackgroundColor = isOddWeek ? "rgb(240, 240, 240)" : "transparent"; // Light Grey / Transparent

    // **🔹 Ensure Editing & Today colors take priority**
    const backgroundColor = isEditing
      ? "rgb(180, 220, 251)"  // Light Blue for Editing
      : isToday
      ? "rgb(251, 207, 180)"  // Peach for Today
      : (selectedRange === "month" || selectedRange === "ytd")
      ? ytdBackgroundColor  // Alternating colors for YTD & Month views
      : "transparent"; // Default

    return (
      <tr
        key={index}
        onClick={() => setEditedRows((prev) => ({ ...prev, [date]: { ...row } }))} // Clicking starts editing
        style={{
          backgroundColor,
          border: isToday ? "2px solid rgb(247, 176, 131)" : isEditing ? "2px solid rgb(140, 180, 220)" : "none",
          cursor: "pointer", // Indicate that the row is clickable
        }}
      >
        {/* Display two-letter day of the week (shifted back one day) */}
        <td><strong>{dayOfWeek}</strong></td>

        {/* Display the adjusted date with +1 to match correct display */}
        <td>  
          {new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toLocaleDateString("en-US", { 
            year: "2-digit", 
            month: "numeric", 
            day: "numeric" 
          })}
        </td>

        {["calls", "appts", "sits", "sales", "alp", "refs", "refAppt", "refSit", "refSale", "refAlp"].map((field) => (
          <td className="editing-cell" key={field}>
<input
  type="text"
  inputMode="decimal"
  pattern="[0-9]*[.]?[0-9]*"
  value={
    editedRows[date]?.[field] !== undefined
      ? editedRows[date][field] // Use the edited value
      : row[field] ?? "" // Use the fetched data
  }
  onChange={(e) => {
    const value = e.target.value;
  
    // Ensure valid number input
    if (/^\d*\.?\d*$/.test(value)) {
      setEditedRows((prev) => {
        const updatedRows = {
          ...prev,
          [date]: {
            ...prev[date],
            reportDate: date,
            [field]: value, // Keep as string to preserve decimal points
          },
        };
        setTotals(calculateTotals()); // Recalculate totals
        return updatedRows;
      });
    }
  }}
  
  onBlur={(e) => {
    const value = e.target.value;
    setEditedRows((prev) => ({
      ...prev,
      [date]: {
        ...prev[date],
        reportDate: date,
        [field]: value === "" ? "" : parseFloat(value), // Convert on blur
      },
    }));
  }}
  onFocus={(e) => e.target.select()} // Auto-select when focused
/>


          </td>
        ))}
      </tr>
    );
})}
</tbody>


        </table>
        </div>
      )}
    </div>
  );
};

export default DailyActivityReport;
