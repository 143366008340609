import React, { useState, useEffect } from 'react';
import LeaderboardChild from './LeaderboardChild';
import './Leaderboard.css';
import LeaderboardPlaceholder from '../utils/LeaderboardPlaceholder';
import axios from 'axios';
import Select from 'react-select';

const Leaderboard = ({ dashboardView = false }) => {
    const [isF6, setIsF6] = useState(false); // Toggle state for F6
    const [experienceFilter, setExperienceFilter] = useState('all'); // Default to "all"
    const [netOrGross, setNetOrGross] = useState('net'); // Default to Net
    const [expandedLeaderboard, setExpandedLeaderboard] = useState(null); // Track which leaderboard is expanded
    const [loading, setLoading] = useState(false);

const [mgaOptions, setMgaOptions] = useState([]);
const [rgaOptions, setRgaOptions] = useState([]);
const [treeOptions, setTreeOptions] = useState([]);
const [selectedMGA, setSelectedMGA] = useState(null);
const [selectedRGA, setSelectedRGA] = useState(null);
const [selectedTree, setSelectedTree] = useState(null);
const [allOptions, setAllOptions] = useState([]);
const fetchUniqueMGAOptions = async () => {
    try {
        const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getUniqueMGAOptions');
        if (response.data.success) {
            const options = response.data.data;

            // Store the original options to allow filtering
            const allOptions = options;

            // Populate unique dropdown values for initial state
            const uniqueRGAs = [...new Set(options.map((opt) => opt.rga))];
            const uniqueTrees = [...new Set(options.map((opt) => opt.tree))];

            setMgaOptions(options.map((opt) => ({ value: opt.lagnname, label: opt.lagnname })));
            setRgaOptions(uniqueRGAs.map((value) => ({ value, label: value })));
            setTreeOptions(uniqueTrees.map((value) => ({ value, label: value })));

            // Save all options for filtering later
            setAllOptions(allOptions);
        } else {
            console.warn('No MGA options found.');
        }
    } catch (error) {
        console.error('Error fetching unique MGA options:', error);
    }
};

    const [data, setData] = useState([]);
    const [loadingStates, setLoadingStates] = useState({
        all: true,
        sa: true,
        ga: true,
        mga: true,
        rga: true,
    });
    const [error, setError] = useState(null);
    const [reportDates, setReportDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [defaultDate, setDefaultDate] = useState('');
    const [reportType, setReportType] = useState('Weekly Recap'); // Default to Weekly Recap

    const calculateDateRange = (date) => {
        const formatToMMDDYYYY = (dateObj) => {
            const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
            const dd = String(dateObj.getDate()).padStart(2, '0');
            const yyyy = dateObj.getFullYear();
            return `${mm}/${dd}/${yyyy}`;
        };

        const reportDate = new Date(date);
        const startDate = new Date(reportDate);
        startDate.setDate(reportDate.getDate() - 3);

        const endDate = new Date(reportDate);
        endDate.setDate(reportDate.getDate() + 3);

        return {
            startDate: formatToMMDDYYYY(startDate),
            endDate: formatToMMDDYYYY(endDate),
        };
    };

    const fetchReportDates = async () => {
        try {
            const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getReportDates');
            if (response.data.success) {
                setReportDates(response.data.reportDates);
                setDefaultDate(response.data.defaultDate);
                setSelectedDate(response.data.defaultDate); // Set the default selected date
            } else {
                console.error('Failed to fetch report dates');
            }
        } catch (error) {
            console.error('Error fetching report dates:', error);
        }
    };


    
    const fetchData = async (endpoint, params) => {
        try {
            const queryParams = new URLSearchParams(params).toString();
            const response = await axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/${endpoint}?${queryParams}`);
            console.log(response.data);
            return response.data.success ? response.data.data : [];
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
            return [];
        }
    };

    const reformatName = (name) => {
        if (!name) return "N/A";
        const parts = name.split(" ");
        if (parts.length === 2) return `${parts[1]} ${parts[0]}`;
        if (parts.length === 3) return `${parts[1]} ${parts[0]}`;
        if (parts.length === 4) return `${parts[1]} ${parts[0]} ${parts[3]}`;
        return name;
    };

    const prepareData = (rows, key, isF6, netOrGross) => {
        const today = new Date();
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(today.getFullYear() - 1);
    
        const filterByExperience = (row) => {
            let formattedDate = null;
    
            if (key === 'mga' && row.start) {
                // Convert start date from MM/DD/YYYY to a proper date object
                const [month, day, year] = row.start.split('/');
                formattedDate = new Date(`${year}-${month}-${day}`);
            } else if (row.esid) {
                // Convert esid date from "YYYY-MM-DDTHH:MM:SS.000Z" to YYYY-MM-DD
                formattedDate = new Date(row.esid.split('T')[0]);
            }
    
            if (!formattedDate) return true; // If no date is found, include the row by default
    
            if (key === 'mga') {
                return experienceFilter === 'rookie'
                    ? formattedDate > oneYearAgo
                    : experienceFilter === 'veteran'
                    ? formattedDate <= oneYearAgo
                    : true;
            } else {
                return experienceFilter === 'rookie'
                    ? formattedDate > new Date('2023-12-31')
                    : experienceFilter === 'veteran'
                    ? formattedDate <= new Date('2023-12-31')
                    : true;
            }
        };
    
        return rows
            .filter(filterByExperience)
            .map((row) => {
                let alpValue;
    
                // Determine ALP value based on leaderboard type, F6 toggle, and Net/Gross selection
                if (key === 'sa') {
                    alpValue = isF6
                        ? netOrGross === 'net'
                            ? row.LVL_2_F6_NET
                            : row.LVL_2_F6_GROSS
                        : netOrGross === 'net'
                        ? row.LVL_2_NET
                        : row.LVL_2_GROSS;
                } else if (['ga', 'mga', 'rga'].includes(key)) {
                    alpValue = isF6
                        ? netOrGross === 'net'
                            ? row.LVL_3_F6_NET
                            : row.LVL_3_F6_GROSS
                        : netOrGross === 'net'
                        ? row.LVL_3_NET
                        : row.LVL_3_GROSS;
                } else {
                    // Default for Top Producers (non SA/GA/MGA/RGA)
                    alpValue = netOrGross === 'net' ? row.LVL_1_NET : row.LVL_1_GROSS;
                }
    
                return {
                    ...row,
                    reformattedName: reformatName(row.LagnName),
                    profilePic: row.profpic?.includes("via.placeholder.com")
                        ? reformatName(row.LagnName).charAt(0)
                        : row.profpic,
                    alpValue: alpValue || "N/A",
                };
            })
            .sort((a, b) => {
                const aValue = parseFloat(a.alpValue) || 0;
                const bValue = parseFloat(b.alpValue) || 0;
                return bValue - aValue; // Descending order
            });
    };
    
    
    const loadLeaderboardData = async (title, endpoint, key) => {
        const { startDate, endDate } = calculateDateRange(selectedDate);
    
        let filters = {
            startDate,
            endDate,
            report: reportType,
            MGA_NAME: selectedMGA?.value || '',
            rga: selectedRGA?.value || '',
            tree: selectedTree?.value || '',
        };
    
        // 🔹 Apply RGA filtering for MGA leaderboard
        if (key === 'mga' && selectedRGA) {
            filters = { ...filters, MGA_NAME: '', rga: selectedRGA.value };
        }
    
        // 🔹 Apply Tree filtering for RGA leaderboard
        if (key === 'rga' && selectedTree) {
            filters = { ...filters, MGA_NAME: '', rga: '', tree: selectedTree.value };
        }
    
        try {
            const rows = await fetchData(endpoint, filters);
    
            setData((prevData) => {
                const existingIndex = prevData.findIndex((board) => board.title === title);
                const newBoard = { title, rows: prepareData(rows, key, isF6, netOrGross) };
    
                if (existingIndex > -1) {
                    const updatedData = [...prevData];
                    updatedData[existingIndex] = newBoard;
                    return updatedData;
                }
    
                return [...prevData, newBoard];
            });
        } catch (err) {
            console.error(`Failed to fetch data for ${title}.`, err);
        } finally {
            setLoadingStates((prev) => ({ ...prev, [key]: false }));
        }
    };
    
    
    
    useEffect(() => {
        fetchReportDates();
        fetchUniqueMGAOptions(); // Fetch unique MGA, RGA, and Tree options
    }, []);
    
    useEffect(() => {
        if (selectedDate) {
            console.log('Fetching leaderboard data with filters:', {
                selectedDate,
                reportType,
                selectedMGA: selectedMGA?.value || 'None',
                selectedRGA: selectedRGA?.value || 'None',
                selectedTree: selectedTree?.value || 'None',
                isF6,
                netOrGross,
                experienceFilter,
            });
    
            setLoadingStates({
                all: true,
                sa: true,
                ga: true,
                mga: true,
                rga: true,
            });
    
            loadLeaderboardData('All Data', 'getweeklyall', 'all');
            loadLeaderboardData('Top SAs', 'getweeklysa', 'sa');
            loadLeaderboardData('Top GAs', 'getweeklyga', 'ga');
            loadLeaderboardData('Top MGAs', 'getweeklymga', 'mga');
            loadLeaderboardData('Top RGAs', 'getweeklyrga', 'rga');
        }
    }, [selectedDate, reportType, selectedMGA, selectedRGA, selectedTree, isF6, netOrGross, experienceFilter]);
    
    
    useEffect(() => {
        if (selectedRGA) {
            const filteredMGAOptions = allOptions
                .filter((opt) => opt.rga === selectedRGA.value)
                .map((opt) => ({ value: opt.lagnname, label: opt.lagnname }));
    
            console.log(`Filtered MGA_NAME options based on RGA (${selectedRGA.value}):`, 
                filteredMGAOptions.map((opt) => opt.value)
            );
    
            setMgaOptions(filteredMGAOptions);
        } else {
            const uniqueMGAs = [...new Set(allOptions.map((opt) => opt.lagnname))];
            console.log('No RGA selected. Resetting MGA_NAME options to all:', uniqueMGAs);
    
            setMgaOptions(uniqueMGAs.map((value) => ({ value, label: value })));
        }
    }, [selectedRGA, allOptions]);
    
    
    const sortedData = [
        { title: 'Top Producers', key: 'all' }, 
        { title: 'Top SAs', key: 'sa' },
        { title: 'Top GAs', key: 'ga' },
        { title: 'Top MGAs', key: 'mga' },
        { title: 'Top RGAs', key: 'rga' },
    ].map((item) => ({
        ...item,
        rows: data.find((board) =>
            board.title === (item.key === 'all' ? 'All Data' : item.title)
        )?.rows || [],
    }));
    

    const formatToMMDDYYYY = (dateStr) => {
        const date = new Date(dateStr);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${mm}/${dd}/${yyyy}`;
    };

    const handlePreviousDate = () => {
        const currentIndex = reportDates.indexOf(selectedDate);
        if (currentIndex > 0) {
            setSelectedDate(reportDates[currentIndex - 1]);
        }
    };

    const handleNextDate = () => {
        const currentIndex = reportDates.indexOf(selectedDate);
        if (currentIndex < reportDates.length - 1) {
            setSelectedDate(reportDates[currentIndex + 1]);
        }
    };

    return (
        <div>
    {!dashboardView && (
<div className="filters-row-container">
    {/* Left-Aligned Experience Filter */}

    <div className="experience-filter-container">
        <span 
            className={experienceFilter === 'all' ? 'selected' : 'unselected'} 
            onClick={() => setExperienceFilter('all')}
        >
            All
        </span>
        <span className="separator">|</span>
        <span 
            className={experienceFilter === 'rookie' ? 'selected' : 'unselected'} 
            onClick={() => setExperienceFilter('rookie')}
        >
            Rookie
        </span>
        <span className="separator">|</span>
        <span 
            className={experienceFilter === 'veteran' ? 'selected' : 'unselected'} 
            onClick={() => setExperienceFilter('veteran')}
        >
            Veteran
        </span>
    </div>
    {/* Center-Aligned Tabs (Week, MTD, YTD) */}

    <div className="tabs-filter-container">
        <span 
            className={reportType === 'Weekly Recap' ? 'selected' : 'unselected'} 
            onClick={() => setReportType('Weekly Recap')}
        >
            Week
        </span>
        <span className="separator">|</span>
        <span 
            className={reportType === 'MTD Recap' ? 'selected' : 'unselected'} 
            onClick={() => setReportType('MTD Recap')}
        >
            MTD
        </span>
        <span className="separator">|</span>
        <span 
            className={reportType === 'YTD Recap' ? 'selected' : 'unselected'} 
            onClick={() => setReportType('YTD Recap')}
        >
            YTD
        </span>
    </div>
    {/* Right-Aligned Toggle (Net/Gross & F6) */}

    <div className="toggle-container">
        <span 
            className={isF6 ? 'selected' : 'unselected'} 
            onClick={() => setIsF6(!isF6)}
        >
            F6
        </span>
        <span className="separator">|</span>
        <span 
            className={netOrGross === 'net' ? 'selected' : 'unselected'} 
            onClick={() => setNetOrGross('net')}
        >
            Net
        </span>
        <span className="separator">|</span>
        <span 
            className={netOrGross === 'gross' ? 'selected' : 'unselected'} 
            onClick={() => setNetOrGross('gross')}
        >
            Gross
        </span>
    </div>
</div>
        )}

{/* Date Dropdown (Below Centered Filters) */}
{!dashboardView && (

<div className="date-select-container">
    <button className="arrow-change-button" onClick={handleNextDate}>&lt;</button>
    <select
        id="report-date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        style={{ margin: '0 10px', padding: '5px' }}
    >
        {reportDates.map((date) => (
            <option key={date} value={date}>
                {formatToMMDDYYYY(date)}
            </option>
        ))}
    </select>
    <button className="arrow-change-button" onClick={handlePreviousDate}>&gt;</button>
</div>
    )}
    
            {/* Centered MGA, RGA, Tree Filters */}
            {!dashboardView && (

            <div className="filter-container">
            <Select
    options={mgaOptions}
    onChange={(value) => setSelectedMGA(value)}
    isClearable
    placeholder="Filter by MGA"
    styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensure dropdown appears above
    }}
    menuPortalTarget={document.body} // Render dropdown outside parent container
/>
<Select
    options={rgaOptions}
    onChange={(value) => setSelectedRGA(value)}
    isClearable
    placeholder="Filter by RGA"
    styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensure dropdown appears above
    }}
    menuPortalTarget={document.body} // Render dropdown outside parent container
/>
<Select
    options={treeOptions}
    onChange={(value) => setSelectedTree(value)}
    isClearable
    placeholder="Filter by Tree"
    styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }) // Ensure dropdown appears above
    }}
    menuPortalTarget={document.body} // Render dropdown outside parent container
/>

            </div>
                )}

{dashboardView && (
    <div>
    <div className='header-row'>
    <h5 
    style={{ 
        cursor: 'pointer', 
    }} 
    title="View full leaderboards"
    onClick={() => window.location.href = '/leaderboard'}
>
    Leaderboard
</h5>
        {/* Left-Aligned Tabs Filter */}
        <div className="tabs-filter-container">
            <span 
                className={reportType === 'Weekly Recap' ? 'selected' : 'unselected'} 
                onClick={() => setReportType('Weekly Recap')}
            >
                Week
            </span>
            <span className="separator">|</span>
            <span 
                className={reportType === 'MTD Recap' ? 'selected' : 'unselected'} 
                onClick={() => setReportType('MTD Recap')}
            >
                MTD
            </span>
            <span className="separator">|</span>
            <span 
                className={reportType === 'YTD Recap' ? 'selected' : 'unselected'} 
                onClick={() => setReportType('YTD Recap')}
            >
                YTD
            </span>
        </div>

        {/* Right-Aligned Date Select */}
        <div className="date-select-container">
            <button className="arrow-change-button" onClick={handleNextDate}>&lt;</button>
            <select
                id="report-date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                style={{ margin: '0 10px', padding: '5px' }}
            >
                {reportDates.map((date) => (
                    <option key={date} value={date}>
                        {formatToMMDDYYYY(date)}
                    </option>
                ))}
            </select>
            <button className="arrow-change-button" onClick={handlePreviousDate}>&gt;</button>
        </div>
    </div>
    </div>
)}


            {/* Leaderboard Layout */}
            <div className={dashboardView ? 'leaderboard-dashboard' : 'leaderboard-layout'}>
            <div className="leaderboard-left">
        {sortedData
            .filter((board) => board.title === 'Top Producers')
            .map((board, index) =>
                loadingStates[board.key] ? (
                    <LeaderboardPlaceholder key={index} title={board.title} />
                ) : (
                    <LeaderboardChild 
                        key={index} 
                        title={board.title} 
                        rows={board.rows} 
                        isF6={isF6} 
                        experienceFilter={experienceFilter}
                        netOrGross={netOrGross}
                        isFullScreen={expandedLeaderboard === "Top Producers"}
                        setExpandedLeaderboard={setExpandedLeaderboard}
                    />
                )
            )}
    </div>
                {!dashboardView && (

                <div className="leaderboard-right">
                    {sortedData
                        .filter((board) => board.title !== 'Top Producers')
                        .slice(0, 4)
                        .map((board, index) =>
                            loadingStates[board.key] ? (
                                <LeaderboardPlaceholder key={index} title={board.title} />
                            ) : (
                                <LeaderboardChild 
                                    key={index} 
                                    title={board.title} 
                                    rows={board.rows} 
                                    isF6={isF6} 
                                    experienceFilter={experienceFilter}
                                    netOrGross={netOrGross} 
                                    isFullScreen={expandedLeaderboard === board.title}
                                    setExpandedLeaderboard={setExpandedLeaderboard}
                                />
                            )
                        )}
                </div>
                                            )}

            </div>
        </div>
    );
    
};

export default Leaderboard;
