import React, { useState, useRef, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FaRegCopy, FaCheck } from 'react-icons/fa';
import '../TableView.css';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import OptionIcon from '../../../img/option.svg';

const Applicants = ({ applicants, advanceStep, updateFinalTime, updateCallbackTime, handleSavePreLicInfo }) => {
  const [copied, setCopied] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [finalTimePopupVisible, setFinalTimePopupVisible] = useState(false);
  const [callbackTimePopupVisible, setCallbackTimePopupVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [finalDate, setFinalDate] = useState('');
  const [finalTime, setFinalTime] = useState('');
  const [callbackDate, setCallbackDate] = useState('');
  const [callbackTime, setCallbackTime] = useState('');
  const [hiredPopupVisible, setHiredPopupVisible] = useState(false);
const [residentState, setResidentState] = useState('');
const [enrolled, setEnrolled] = useState('');
const [course, setCourse] = useState('');
const [expectedCompleteDate, setExpectedCompleteDate] = useState('');
const [detailsExpanded, setDetailsExpanded] = useState(false);
const [detailsModalVisible, setDetailsModalVisible] = useState(false);
const [selectedRecruit, setSelectedRecruit] = useState(null);
const [filteredApplicants, setFilteredApplicants] = useState([]);
const [showAll, setShowAll] = useState(false); // Toggle to show all rows
const userId = localStorage.getItem('userId'); // Retrieve userId from local storage

useEffect(() => {
  if (!showAll) {
    // Filter applicants by logged-in agent's userId
    const filtered = applicants.filter(applicant => applicant.recruiting_agent === userId);
    setFilteredApplicants(filtered);
  } else {
    // Show all applicants if toggle is enabled
    setFilteredApplicants(applicants);
  }
}, [applicants, showAll, userId]);

const handleToggleShowAll = () => {
  setShowAll(prevState => !prevState);
};

  const menuRef = useRef(null);
  const rowRef = useRef(null);
  const affiliateLink = `https://ariaslife.com/careers/?hm=${userId}`;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const formatDateTime = (dateTimeString) => {
    // Create a date object assuming the time from the database is in MST (UTC-7)
    const mstOffset = 7 * 60; // MST is UTC-7 in minutes
    const date = new Date(dateTimeString);
  
    // Convert the MST time to UTC time
    const utcTime = date.getTime() + mstOffset * 60 * 1000;
  
    // Create a new date object with the UTC time
    const localTime = new Date(utcTime);
  
    // Format the date in the user's local time zone with 12-hour format and AM/PM
    const formattedDate = localTime.toLocaleString('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true // 12-hour format with AM/PM
    });
  
    return formattedDate;
  };
  
  
  
  const formatApplicantName = (first, middle, last, suffix) => {
    return [first, middle, last, suffix].filter(Boolean).join(' ');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(affiliateLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 4000); // Reset the icon after 4 seconds
    });
  };

  
  const handleActionClick = (event, applicant) => {
    event.stopPropagation();
    setMenuPosition({ x: event.clientX, y: event.clientY });
    setMenuVisible(true);
    setSelectedApplicant(applicant);
  };
  
  const handleDetailsClick = (event, recruit) => {
    event.stopPropagation(); // Prevent the click from triggering the menu
    setSelectedRecruit(recruit);
    setDetailsModalVisible(true);
};


const handleMenuOptionClick = (option) => {
  if (option === "Booked Final" && selectedApplicant) {
    setFinalTimePopupVisible(true); // Show Final Time popup
  } else if (option === "Called Schedule Callback" && selectedApplicant) {
    setCallbackTimePopupVisible(true); // Show Callback Time popup
  } else if (option === "Hired" && selectedApplicant) {
    setHiredPopupVisible(true); // Show Hired popup
  } else if (option === "Didn't Hire" && selectedApplicant) {
    advanceStep(selectedApplicant.id, "Not Interested");
  } else if (option === "Called No Answer" && selectedApplicant) {
    advanceStep(selectedApplicant.id, "No Answer - Career Form");
  }
};

  
  const [sectionsVisible, setSectionsVisible] = useState({
    careerForm: true,
    noAnswer: true,
    callbackScheduled: true,
    bookedFinal: true,
    notInterested: true,
    hired: true,
  });
  
  const toggleSection = (section) => {
    setSectionsVisible((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  

  const handleSaveFinalTime = async () => {
    const finalDateTime = `${finalDate} ${finalTime}`;
    await updateFinalTime(selectedApplicant.id, finalDateTime);
    advanceStep(selectedApplicant.id, "Final");
    setFinalTimePopupVisible(false);
    setMenuVisible(false);
  };

  const handleSaveCallbackTime = async () => {
    const callbackDateTime = `${callbackDate} ${callbackTime}`;
    await updateCallbackTime(selectedApplicant.id, callbackDateTime);
    advanceStep(selectedApplicant.id, "Callback - Career Form");
    setCallbackTimePopupVisible(false);
    setMenuVisible(false);
  };

  const handleSaveHiredInfo = async () => {
    const preLicData = {
      resident_state: residentState,
      enrolled,
      course,
      expected_complete_date: expectedCompleteDate,
    };
  
    await handleSavePreLicInfo(selectedApplicant.id, preLicData);
    advanceStep(selectedApplicant.id, "Pre-Lic");
    setHiredPopupVisible(false);
    setMenuVisible(false);
  };
  

  const handleClickOutside = (event) => {
    if (
      menuRef.current && !menuRef.current.contains(event.target)
    ) {
      setMenuVisible(false);
      setFinalTimePopupVisible(false);
      setCallbackTimePopupVisible(false);
      setHiredPopupVisible(false); // Ensure the hired popup is also hidden
    }
  };
  
  useEffect(() => {
    if (menuVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuVisible]);
  
  

  const careerFormApplicants = filteredApplicants.filter(applicant => applicant.step === 'Careers Form');
  const noAnswerCareerFormApplicants = filteredApplicants.filter(applicant => applicant.step === 'No Answer - Career Form');
  const calledScheduleCallbackApplicants = filteredApplicants.filter(applicant => applicant.step === 'Callback - Career Form');
  const bookedFinalApplicants = filteredApplicants.filter(applicant => applicant.step === 'Final');
  const notInterestedApplicants = filteredApplicants.filter(applicant => applicant.step === 'Not Interested');
  const hiredApplicants = filteredApplicants.filter(applicant => applicant.step === 'Pre-Lic');

  useEffect(() => {
    console.log('Popup States:', { finalTimePopupVisible, callbackTimePopupVisible, hiredPopupVisible });
}, [finalTimePopupVisible, callbackTimePopupVisible, hiredPopupVisible]);


  

  return (
      <div className="large-app-container">


<div className="applicants-affiliate-link" style={{ textAlign: 'center' }}>
  <p style={{ fontSize: '14px', display: 'inline-flex', alignItems: 'center', marginBottom: '10px' }}>
    My Affiliate Link 
    <span onClick={handleCopy} style={{ marginLeft: '10px', cursor: 'pointer' }}>
      {copied ? <FaCheck color="green" /> : <FaRegCopy />}
    </span>
  </p>
  <br />
  {/* Add the actual hyperlink to the affiliate link */}
  <a href={affiliateLink} target="_blank" rel="noopener noreferrer">
    {affiliateLink}
  </a>
</div>
<div style={{ textAlign: 'center', marginBottom: '20px' }}>
  <button
    onClick={handleToggleShowAll}
    className='insured-button'
  >
    {showAll ? 'Show Only My Applicants' : 'Show All Applicants'}
  </button>
</div>


        <Modal show={detailsModalVisible} onHide={() => setDetailsModalVisible(false)}>
        <Modal.Body>
          {selectedRecruit && (
            <>
              <p><strong>Applicant:</strong> {formatApplicantName(selectedRecruit.recruit_first, selectedRecruit.recruit_middle, selectedRecruit.recruit_last, selectedRecruit.recruit_suffix)}</p>
              <p><strong>Date Added:</strong> {formatDateTime(selectedRecruit.date_added)}</p>
              <p><strong>Phone:</strong> {selectedRecruit.phone}</p>
              <p><strong>Email:</strong> {selectedRecruit.email}</p>
              <p><strong>Aspects:</strong> {selectedRecruit.Aspects}</p>
              <p><strong>Concern:</strong> {selectedRecruit.Concern}</p>
              <p><strong>Spouse:</strong> {selectedRecruit.Spouse}</p>
              <p><strong>Career Goals:</strong> {selectedRecruit.CareerGoals}</p>
              <p><strong>Compensation:</strong> {selectedRecruit.Compensation}</p>
              <p><strong>Why Choose:</strong> {selectedRecruit.WhyChoose}</p>
              <p><strong>Res State:</strong> {selectedRecruit.resident_state}</p>
            </>
          )}
        </Modal.Body>
      </Modal>

        <Tabs>
        <TabList>
          <Tab>Applicants</Tab>
          <Tab>No Answer</Tab>
          <Tab>Callback</Tab>
          <Tab>Booked Final</Tab>
          <Tab>Not Interested</Tab>
          <Tab>Hired</Tab>
        </TabList>

<TabPanel>
        <div className="recruiting-table-container">
        <h3>
  <span>Applicants</span>

</h3>

<table className="recruiting-table">
    <thead>
      <tr>
        <th></th>
        <th>Recruiting Agent</th>
        <th>Applicant</th>
        <th>Res State</th>
        <th>Date</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Aspects</th>
        <th>Concern</th>
        <th>Spouse</th>
        <th>Career Goals</th>
        <th>Compensation</th>
        <th>Why Choose</th>
      </tr>
    </thead>
    <tbody>
  {careerFormApplicants
    .filter((applicant) => applicant.step === 'Careers Form')
    .map((applicant) => (
      <tr key={applicant.id}>
        <td>
          <button onClick={(e) => handleActionClick(e, applicant)}>            
            <img src={OptionIcon} alt="Options" />
          </button>
        </td>
        <td className="applicants-column-long">{applicant.lagnname}</td>
        <td>{formatApplicantName(applicant.recruit_first, applicant.recruit_middle, applicant.recruit_last, applicant.recruit_suffix)}</td>
        <td>{applicant.resident_state}</td>
        <td>{formatDateTime(applicant.date_added)}</td>
        <td className="phone-link">
          <a href={`tel:${applicant.phone}`}>{applicant.phone}</a>
        </td>
        <td>{applicant.email}</td>
        <td className="applicants-column-long">{applicant.Aspects}</td>
        <td className="applicants-column-long">{applicant.Concern}</td>
        <td className="applicants-column-long">{applicant.Spouse}</td>
        <td className="applicants-column-long">{applicant.CareerGoals}</td>
        <td className="applicants-column-long">{applicant.Compensation}</td>
        <td className="applicants-column-long">{applicant.WhyChoose}</td>
      </tr>
    ))}
</tbody>

  </table>
          {menuVisible && (
  <div
    ref={menuRef}
    className="popup-menu"
    style={{
      position: 'fixed',
      top: menuPosition.y,
      left: menuPosition.x,
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      borderRadius: '4px',
    }}
  >
    <button onClick={() => handleMenuOptionClick('Booked Final')}>Booked Final</button>
    <button onClick={() => handleMenuOptionClick("Didn't Hire")}>Didn't Hire</button>
    <button onClick={() => handleMenuOptionClick("Called No Answer")}>Called No Answer</button>
    <button onClick={() => handleMenuOptionClick("Called Schedule Callback")}>Called Schedule Callback</button>
    <button onClick={() => handleMenuOptionClick("Hired")}>Hired</button> {/* New Option */}
    {finalTimePopupVisible && (
      <div
      className="final-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Final Time</h4>
      <input
        type="date"
        value={finalDate}
        onChange={(e) => setFinalDate(e.target.value)}
      />
      <input
        type="time"
        value={finalTime}
        onChange={(e) => setFinalTime(e.target.value)}
      />
      <button onClick={handleSaveFinalTime}>Save</button>
    </div>
  )}

  {/* Callback Time Popup */}
  {callbackTimePopupVisible && (
    <div
      className="callback-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Callback Time</h4>
      <input
        type="date"
        value={callbackDate}
        onChange={(e) => setCallbackDate(e.target.value)}
      />
      <input
        type="time"
        value={callbackTime}
        onChange={(e) => setCallbackTime(e.target.value)}
      />
      <button onClick={handleSaveCallbackTime}>Save</button>
    </div>
  )}

  {/* Hired Popup */}
  {hiredPopupVisible && (
    <div
      className="hired-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
    <h4>Pre-Licensing Information</h4>
    <label>
      Resident State:
      <select value={residentState} onChange={(e) => setResidentState(e.target.value)}>
  <option value="">Select State</option>
  <option value="AL">Alabama</option>
  <option value="AK">Alaska</option>
  <option value="AZ">Arizona</option>
  <option value="AR">Arkansas</option>
  <option value="CA">California</option>
  <option value="CO">Colorado</option>
  <option value="CT">Connecticut</option>
  <option value="DE">Delaware</option>
  <option value="FL">Florida</option>
  <option value="GA">Georgia</option>
  <option value="HI">Hawaii</option>
  <option value="ID">Idaho</option>
  <option value="IL">Illinois</option>
  <option value="IN">Indiana</option>
  <option value="IA">Iowa</option>
  <option value="KS">Kansas</option>
  <option value="KY">Kentucky</option>
  <option value="LA">Louisiana</option>
  <option value="ME">Maine</option>
  <option value="MD">Maryland</option>
  <option value="MA">Massachusetts</option>
  <option value="MI">Michigan</option>
  <option value="MN">Minnesota</option>
  <option value="MS">Mississippi</option>
  <option value="MO">Missouri</option>
  <option value="MT">Montana</option>
  <option value="NE">Nebraska</option>
  <option value="NV">Nevada</option>
  <option value="NH">New Hampshire</option>
  <option value="NJ">New Jersey</option>
  <option value="NM">New Mexico</option>
  <option value="NY">New York</option>
  <option value="NC">North Carolina</option>
  <option value="ND">North Dakota</option>
  <option value="OH">Ohio</option>
  <option value="OK">Oklahoma</option>
  <option value="OR">Oregon</option>
  <option value="PA">Pennsylvania</option>
  <option value="RI">Rhode Island</option>
  <option value="SC">South Carolina</option>
  <option value="SD">South Dakota</option>
  <option value="TN">Tennessee</option>
  <option value="TX">Texas</option>
  <option value="UT">Utah</option>
  <option value="VT">Vermont</option>
  <option value="VA">Virginia</option>
  <option value="WA">Washington</option>
  <option value="WV">West Virginia</option>
  <option value="WI">Wisconsin</option>
  <option value="WY">Wyoming</option>
</select>

    </label>
    <label>
      Enrolled:
      <select value={enrolled} onChange={(e) => setEnrolled(e.target.value)}>
        <option value="">Select</option>
        <option value="y">Yes</option>
        <option value="n">No</option>
      </select>
    </label>
    <label>
      Course:
      <input type="text" value={course} onChange={(e) => setCourse(e.target.value)} />
    </label>
    <label>
      Expected Completion Date:
      <input type="date" value={expectedCompleteDate} onChange={(e) => setExpectedCompleteDate(e.target.value)} />
    </label>
    <button onClick={handleSaveHiredInfo}>Save</button>
  </div>
)}

  </div>
)}

        </div>
</TabPanel>

<TabPanel>
{/* No Answer - Career Form Table */}
<div className="recruiting-table-container">
<h3>
  <span>No Answer</span>

</h3>

    <table className="recruiting-table">
    <thead>
      <tr>
        <th>Recruiting Agent</th>
        <th>Applicant</th>
        <th>Res State</th>
        <th>Date</th>
        <th>Last Called</th>
        <th>Phone</th>
        <th>Email</th>
        <th></th>

      </tr>
    </thead>
    <tbody>
  {noAnswerCareerFormApplicants.map((applicant) => (
    <tr 
      key={applicant.id} 
      onClick={(e) => handleActionClick(e, applicant)} 
      ref={rowRef} 
      className={menuVisible && selectedApplicant?.id === applicant.id ? 'popup-open' : ''}
    >
      <td className="applicants-column-long">{applicant.lagnname}</td>
      <td>{formatApplicantName(applicant.recruit_first, applicant.recruit_middle, applicant.recruit_last, applicant.recruit_suffix)}</td>
      <td>{applicant.resident_state}</td>
      <td>{formatDateTime(applicant.date_added)}</td>
      <td>{formatDateTime(applicant.date_last_updated)}</td>
      <td className="phone-link">
  <a href={`tel:${applicant.phone}`}>{applicant.phone}</a>
</td>

      <td>{applicant.email}</td>
      <td>
        <button 
          className="details-button" 
          onClick={(e) => handleDetailsClick(e, applicant)}
        >
          Details
        </button>
      </td>
    </tr>
  ))}
</tbody>


  </table>
  {menuVisible && (
  <div
    ref={menuRef}
    className="popup-menu"
    style={{
      position: 'fixed',
      top: menuPosition.y,
      left: menuPosition.x,
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      padding: '8px',
      borderRadius: '4px',
    }}
  >
    <button onClick={() => handleMenuOptionClick('Booked Final')}>Booked Final</button>
    <button onClick={() => handleMenuOptionClick("Didn't Hire")}>Didn't Hire</button>
    <button onClick={() => handleMenuOptionClick("Called No Answer")}>Called No Answer</button>
    <button onClick={() => handleMenuOptionClick("Called Schedule Callback")}>Called Schedule Callback</button>
    <button onClick={() => handleMenuOptionClick("Move to Applicants")}>Move to Applicants</button> {/* New Option */}
    <button onClick={() => handleMenuOptionClick("Hired")}>Hired</button> {/* New Option */}
    {finalTimePopupVisible && (
      <div
      className="final-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Final Time</h4>
      <input
        type="date"
        value={finalDate}
        onChange={(e) => setFinalDate(e.target.value)}
      />
      <input
        type="time"
        value={finalTime}
        onChange={(e) => setFinalTime(e.target.value)}
      />
      <button onClick={handleSaveFinalTime}>Save</button>
    </div>
  )}

  {/* Callback Time Popup */}
  {callbackTimePopupVisible && (
    <div
      className="callback-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Callback Time</h4>
      <input
        type="date"
        value={callbackDate}
        onChange={(e) => setCallbackDate(e.target.value)}
      />
      <input
        type="time"
        value={callbackTime}
        onChange={(e) => setCallbackTime(e.target.value)}
      />
      <button onClick={handleSaveCallbackTime}>Save</button>
    </div>
  )}

  {/* Hired Popup */}
  {hiredPopupVisible && (
    <div
      className="hired-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
    <h4>Pre-Licensing Information</h4>
    <label>
      Resident State:
      <select value={residentState} onChange={(e) => setResidentState(e.target.value)}>
  <option value="">Select State</option>
  <option value="AL">Alabama</option>
  <option value="AK">Alaska</option>
  <option value="AZ">Arizona</option>
  <option value="AR">Arkansas</option>
  <option value="CA">California</option>
  <option value="CO">Colorado</option>
  <option value="CT">Connecticut</option>
  <option value="DE">Delaware</option>
  <option value="FL">Florida</option>
  <option value="GA">Georgia</option>
  <option value="HI">Hawaii</option>
  <option value="ID">Idaho</option>
  <option value="IL">Illinois</option>
  <option value="IN">Indiana</option>
  <option value="IA">Iowa</option>
  <option value="KS">Kansas</option>
  <option value="KY">Kentucky</option>
  <option value="LA">Louisiana</option>
  <option value="ME">Maine</option>
  <option value="MD">Maryland</option>
  <option value="MA">Massachusetts</option>
  <option value="MI">Michigan</option>
  <option value="MN">Minnesota</option>
  <option value="MS">Mississippi</option>
  <option value="MO">Missouri</option>
  <option value="MT">Montana</option>
  <option value="NE">Nebraska</option>
  <option value="NV">Nevada</option>
  <option value="NH">New Hampshire</option>
  <option value="NJ">New Jersey</option>
  <option value="NM">New Mexico</option>
  <option value="NY">New York</option>
  <option value="NC">North Carolina</option>
  <option value="ND">North Dakota</option>
  <option value="OH">Ohio</option>
  <option value="OK">Oklahoma</option>
  <option value="OR">Oregon</option>
  <option value="PA">Pennsylvania</option>
  <option value="RI">Rhode Island</option>
  <option value="SC">South Carolina</option>
  <option value="SD">South Dakota</option>
  <option value="TN">Tennessee</option>
  <option value="TX">Texas</option>
  <option value="UT">Utah</option>
  <option value="VT">Vermont</option>
  <option value="VA">Virginia</option>
  <option value="WA">Washington</option>
  <option value="WV">West Virginia</option>
  <option value="WI">Wisconsin</option>
  <option value="WY">Wyoming</option>
</select>
    </label>
    <label>
      Enrolled:
      <select value={enrolled} onChange={(e) => setEnrolled(e.target.value)}>
        <option value="">Select</option>
        <option value="y">Yes</option>
        <option value="n">No</option>
      </select>
    </label>
    <label>
      Course:
      <input type="text" value={course} onChange={(e) => setCourse(e.target.value)} />
    </label>
    <label>
      Expected Completion Date:
      <input type="date" value={expectedCompleteDate} onChange={(e) => setExpectedCompleteDate(e.target.value)} />
    </label>
    <button onClick={handleSaveHiredInfo}>Save</button>
  </div>
)}

  </div>
)}
</div>
</TabPanel>

<TabPanel>
{/* Called Schedule Callback Table */}
<div className="recruiting-table-container">
<h3>
  <span>Callback</span>
</h3>

  <table className="recruiting-table">
    <thead>
      <tr>
        <th>Recruiting Agent</th> 
        <th>Applicant</th>
        <th>Res State</th>
        <th>Date</th>
        <th>Callback</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Aspects</th>
        <th>Concern</th>
        <th>Spouse</th>
        <th>Career Goals</th>
        <th>Compensation</th>
        <th>Why Choose</th>
      </tr>
    </thead>
    <tbody>
      {calledScheduleCallbackApplicants.map((applicant) => (
        <tr
          key={applicant.id}
          onClick={(e) => handleActionClick(e, applicant)}
          ref={rowRef}
          className={menuVisible && selectedApplicant?.id === applicant.id ? 'popup-open' : ''}
        >
          <td className="applicants-column-long">{applicant.lagnname}</td>
          <td>{formatApplicantName(applicant.recruit_first, applicant.recruit_middle, applicant.recruit_last, applicant.recruit_suffix)}</td>
          <td>{applicant.resident_state}</td>
          <td>{formatDateTime(applicant.date_added)}</td>
          <td>{formatDateTime(applicant.callback_time)}</td>
          <td>{applicant.phone}</td>
          <td>{applicant.email}</td>
          <td className="applicants-column-long">{applicant.Aspects}</td>
          <td className="applicants-column-long">{applicant.Concern}</td>
          <td className="applicants-column-long">{applicant.Spouse}</td>
          <td className="applicants-column-long">{applicant.CareerGoals}</td>
          <td className="applicants-column-long">{applicant.Compensation}</td>
          <td className="applicants-column-long">{applicant.WhyChoose}</td>
        </tr>
      ))}
    </tbody>
  </table>
  {menuVisible && (
  <div
    ref={menuRef}
    className="popup-menu"
    style={{
      position: 'fixed',
      top: menuPosition.y,
      left: menuPosition.x,
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      padding: '8px',
      borderRadius: '4px',
    }}
  >
    <button onClick={() => handleMenuOptionClick('Booked Final')}>Booked Final</button>
    <button onClick={() => handleMenuOptionClick("Didn't Hire")}>Didn't Hire</button>
    <button onClick={() => handleMenuOptionClick("Called No Answer")}>Called No Answer</button>
    <button onClick={() => handleMenuOptionClick("Called Schedule Callback")}>Called Schedule Callback</button>
    <button onClick={() => handleMenuOptionClick("Move to Applicants")}>Move to Applicants</button> {/* New Option */}
    <button onClick={() => handleMenuOptionClick("Hired")}>Hired</button> {/* New Option */}
    {finalTimePopupVisible && (
      <div
      className="final-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Final Time</h4>
      <input
        type="date"
        value={finalDate}
        onChange={(e) => setFinalDate(e.target.value)}
      />
      <input
        type="time"
        value={finalTime}
        onChange={(e) => setFinalTime(e.target.value)}
      />
      <button onClick={handleSaveFinalTime}>Save</button>
    </div>
  )}

  {/* Callback Time Popup */}
  {callbackTimePopupVisible && (
    <div
      className="callback-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Callback Time</h4>
      <input
        type="date"
        value={callbackDate}
        onChange={(e) => setCallbackDate(e.target.value)}
      />
      <input
        type="time"
        value={callbackTime}
        onChange={(e) => setCallbackTime(e.target.value)}
      />
      <button onClick={handleSaveCallbackTime}>Save</button>
    </div>
  )}

  {/* Hired Popup */}
  {hiredPopupVisible && (
    <div
      className="hired-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
    <h4>Pre-Licensing Information</h4>
    <label>
      Resident State:
      <select value={residentState} onChange={(e) => setResidentState(e.target.value)}>
  <option value="">Select State</option>
  <option value="AL">Alabama</option>
  <option value="AK">Alaska</option>
  <option value="AZ">Arizona</option>
  <option value="AR">Arkansas</option>
  <option value="CA">California</option>
  <option value="CO">Colorado</option>
  <option value="CT">Connecticut</option>
  <option value="DE">Delaware</option>
  <option value="FL">Florida</option>
  <option value="GA">Georgia</option>
  <option value="HI">Hawaii</option>
  <option value="ID">Idaho</option>
  <option value="IL">Illinois</option>
  <option value="IN">Indiana</option>
  <option value="IA">Iowa</option>
  <option value="KS">Kansas</option>
  <option value="KY">Kentucky</option>
  <option value="LA">Louisiana</option>
  <option value="ME">Maine</option>
  <option value="MD">Maryland</option>
  <option value="MA">Massachusetts</option>
  <option value="MI">Michigan</option>
  <option value="MN">Minnesota</option>
  <option value="MS">Mississippi</option>
  <option value="MO">Missouri</option>
  <option value="MT">Montana</option>
  <option value="NE">Nebraska</option>
  <option value="NV">Nevada</option>
  <option value="NH">New Hampshire</option>
  <option value="NJ">New Jersey</option>
  <option value="NM">New Mexico</option>
  <option value="NY">New York</option>
  <option value="NC">North Carolina</option>
  <option value="ND">North Dakota</option>
  <option value="OH">Ohio</option>
  <option value="OK">Oklahoma</option>
  <option value="OR">Oregon</option>
  <option value="PA">Pennsylvania</option>
  <option value="RI">Rhode Island</option>
  <option value="SC">South Carolina</option>
  <option value="SD">South Dakota</option>
  <option value="TN">Tennessee</option>
  <option value="TX">Texas</option>
  <option value="UT">Utah</option>
  <option value="VT">Vermont</option>
  <option value="VA">Virginia</option>
  <option value="WA">Washington</option>
  <option value="WV">West Virginia</option>
  <option value="WI">Wisconsin</option>
  <option value="WY">Wyoming</option>
</select>
    </label>
    <label>
      Enrolled:
      <select value={enrolled} onChange={(e) => setEnrolled(e.target.value)}>
        <option value="">Select</option>
        <option value="y">Yes</option>
        <option value="n">No</option>
      </select>
    </label>
    <label>
      Course:
      <input type="text" value={course} onChange={(e) => setCourse(e.target.value)} />
    </label>
    <label>
      Expected Completion Date:
      <input type="date" value={expectedCompleteDate} onChange={(e) => setExpectedCompleteDate(e.target.value)} />
    </label>
    <button onClick={handleSaveHiredInfo}>Save</button>
  </div>
)}

  </div>
)}
</div>
</TabPanel>

<TabPanel>
{/* Booked Final Table */}
<div className="recruiting-table-container">
<h3>
  <span>Booked Final</span>
</h3>

  <table className="recruiting-table">
    <thead>
      <tr>
        <th>Recruiting Agent</th>
        <th>Applicant</th>
        <th>Res State</th>
        <th>Date</th>
        <th>Final Interview Date/Time</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Aspects</th>
        <th>Concern</th>
        <th>Spouse</th>
        <th>Career Goals</th>
        <th>Compensation</th>
        <th>Why Choose</th>
      </tr>
    </thead>
    <tbody>
      {bookedFinalApplicants.map((applicant) => (
        <tr
          key={applicant.id}
          onClick={(e) => handleActionClick(e, applicant)}
          ref={rowRef}
          className={menuVisible && selectedApplicant?.id === applicant.id ? 'popup-open' : ''}
        >
          <td className="applicants-column-long">{applicant.lagnname}</td>
          <td>{formatApplicantName(applicant.recruit_first, applicant.recruit_middle, applicant.recruit_last, applicant.recruit_suffix)}</td>
          <td>{applicant.resident_state}</td>
          <td>{formatDateTime(applicant.date_added)}</td>
          <td>{formatDateTime(applicant.final_time)}</td> {/* Assuming final_time stores the final interview date and time */}
          <td>{applicant.phone}</td>
          <td>{applicant.email}</td>
          <td className="applicants-column-long">{applicant.Aspects}</td>
          <td className="applicants-column-long">{applicant.Concern}</td>
          <td className="applicants-column-long">{applicant.Spouse}</td>
          <td className="applicants-column-long">{applicant.CareerGoals}</td>
          <td className="applicants-column-long">{applicant.Compensation}</td>
          <td className="applicants-column-long">{applicant.WhyChoose}</td>
        </tr>
      ))}
    </tbody>
  </table>
  {menuVisible && (
  <div
    ref={menuRef}
    className="popup-menu"
    style={{
      position: 'fixed',
      top: menuPosition.y,
      left: menuPosition.x,
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      padding: '8px',
      borderRadius: '4px',
    }}
  >
    <button onClick={() => handleMenuOptionClick('Booked Final')}>Booked Final</button>
    <button onClick={() => handleMenuOptionClick("Didn't Hire")}>Didn't Hire</button>
    <button onClick={() => handleMenuOptionClick("Called No Answer")}>Called No Answer</button>
    <button onClick={() => handleMenuOptionClick("Called Schedule Callback")}>Called Schedule Callback</button>
    <button onClick={() => handleMenuOptionClick("Move to Applicants")}>Move to Applicants</button> {/* New Option */}
    <button onClick={() => handleMenuOptionClick("Hired")}>Hired</button> {/* New Option */}
    {finalTimePopupVisible && (
      <div
      className="final-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Final Time</h4>
      <input
        type="date"
        value={finalDate}
        onChange={(e) => setFinalDate(e.target.value)}
      />
      <input
        type="time"
        value={finalTime}
        onChange={(e) => setFinalTime(e.target.value)}
      />
      <button onClick={handleSaveFinalTime}>Save</button>
    </div>
  )}

  {/* Callback Time Popup */}
  {callbackTimePopupVisible && (
    <div
      className="callback-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Callback Time</h4>
      <input
        type="date"
        value={callbackDate}
        onChange={(e) => setCallbackDate(e.target.value)}
      />
      <input
        type="time"
        value={callbackTime}
        onChange={(e) => setCallbackTime(e.target.value)}
      />
      <button onClick={handleSaveCallbackTime}>Save</button>
    </div>
  )}

  {/* Hired Popup */}
  {hiredPopupVisible && (
    <div
      className="hired-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
    <h4>Pre-Licensing Information</h4>
    <label>
      Resident State:
      <select value={residentState} onChange={(e) => setResidentState(e.target.value)}>
  <option value="">Select State</option>
  <option value="AL">Alabama</option>
  <option value="AK">Alaska</option>
  <option value="AZ">Arizona</option>
  <option value="AR">Arkansas</option>
  <option value="CA">California</option>
  <option value="CO">Colorado</option>
  <option value="CT">Connecticut</option>
  <option value="DE">Delaware</option>
  <option value="FL">Florida</option>
  <option value="GA">Georgia</option>
  <option value="HI">Hawaii</option>
  <option value="ID">Idaho</option>
  <option value="IL">Illinois</option>
  <option value="IN">Indiana</option>
  <option value="IA">Iowa</option>
  <option value="KS">Kansas</option>
  <option value="KY">Kentucky</option>
  <option value="LA">Louisiana</option>
  <option value="ME">Maine</option>
  <option value="MD">Maryland</option>
  <option value="MA">Massachusetts</option>
  <option value="MI">Michigan</option>
  <option value="MN">Minnesota</option>
  <option value="MS">Mississippi</option>
  <option value="MO">Missouri</option>
  <option value="MT">Montana</option>
  <option value="NE">Nebraska</option>
  <option value="NV">Nevada</option>
  <option value="NH">New Hampshire</option>
  <option value="NJ">New Jersey</option>
  <option value="NM">New Mexico</option>
  <option value="NY">New York</option>
  <option value="NC">North Carolina</option>
  <option value="ND">North Dakota</option>
  <option value="OH">Ohio</option>
  <option value="OK">Oklahoma</option>
  <option value="OR">Oregon</option>
  <option value="PA">Pennsylvania</option>
  <option value="RI">Rhode Island</option>
  <option value="SC">South Carolina</option>
  <option value="SD">South Dakota</option>
  <option value="TN">Tennessee</option>
  <option value="TX">Texas</option>
  <option value="UT">Utah</option>
  <option value="VT">Vermont</option>
  <option value="VA">Virginia</option>
  <option value="WA">Washington</option>
  <option value="WV">West Virginia</option>
  <option value="WI">Wisconsin</option>
  <option value="WY">Wyoming</option>
</select>
    </label>
    <label>
      Enrolled:
      <select value={enrolled} onChange={(e) => setEnrolled(e.target.value)}>
        <option value="">Select</option>
        <option value="y">Yes</option>
        <option value="n">No</option>
      </select>
    </label>
    <label>
      Course:
      <input type="text" value={course} onChange={(e) => setCourse(e.target.value)} />
    </label>
    <label>
      Expected Completion Date:
      <input type="date" value={expectedCompleteDate} onChange={(e) => setExpectedCompleteDate(e.target.value)} />
    </label>
    <button onClick={handleSaveHiredInfo}>Save</button>
  </div>
)}

  </div>
)}
</div>
</TabPanel>

<TabPanel>
{/* Not Interested Table */}
<div className="recruiting-table-container">
<h3>
  <span>Not Interested</span>

</h3>

    <table className="recruiting-table">
    <thead>
      <tr>
        <th>Recruiting Agent</th>
        <th>Applicant</th>
        <th>Res State</th>
        <th>Date</th>
        <th>Last Updated</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Aspects</th>
        <th>Concern</th>
        <th>Spouse</th>
        <th>Career Goals</th>
        <th>Compensation</th>
        <th>Why Choose</th>
      </tr>
    </thead>
    <tbody>
      {notInterestedApplicants.map((applicant) => (
        <tr
          key={applicant.id}
          onClick={(e) => handleActionClick(e, applicant)}
          ref={rowRef}
          className={menuVisible && selectedApplicant?.id === applicant.id ? 'popup-open' : ''}
        >
          <td className="applicants-column-long">{applicant.lagnname}</td>
          <td>{formatApplicantName(applicant.recruit_first, applicant.recruit_middle, applicant.recruit_last, applicant.recruit_suffix)}</td>
          <td>{applicant.resident_state}</td>
          <td>{formatDateTime(applicant.date_added)}</td>
          <td>{formatDateTime(applicant.date_last_updated)}</td>
          <td>{applicant.phone}</td>
          <td>{applicant.email}</td>
          <td className="applicants-column-long">{applicant.Aspects}</td>
          <td className="applicants-column-long">{applicant.Concern}</td>
          <td className="applicants-column-long">{applicant.Spouse}</td>
          <td className="applicants-column-long">{applicant.CareerGoals}</td>
          <td className="applicants-column-long">{applicant.Compensation}</td>
          <td className="applicants-column-long">{applicant.WhyChoose}</td>
        </tr>
      ))}
    </tbody>
  </table>
  {menuVisible && (
  <div
    ref={menuRef}
    className="popup-menu"
    style={{
      position: 'fixed',
      top: menuPosition.y,
      left: menuPosition.x,
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      padding: '8px',
      borderRadius: '4px',
    }}
  >
    <button onClick={() => handleMenuOptionClick('Booked Final')}>Booked Final</button>
    <button onClick={() => handleMenuOptionClick("Didn't Hire")}>Didn't Hire</button>
    <button onClick={() => handleMenuOptionClick("Called No Answer")}>Called No Answer</button>
    <button onClick={() => handleMenuOptionClick("Called Schedule Callback")}>Called Schedule Callback</button>
    <button onClick={() => handleMenuOptionClick("Move to Applicants")}>Move to Applicants</button> {/* New Option */}
    <button onClick={() => handleMenuOptionClick("Hired")}>Hired</button> {/* New Option */}
    {finalTimePopupVisible && (
      <div
      className="final-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Final Time</h4>
      <input
        type="date"
        value={finalDate}
        onChange={(e) => setFinalDate(e.target.value)}
      />
      <input
        type="time"
        value={finalTime}
        onChange={(e) => setFinalTime(e.target.value)}
      />
      <button onClick={handleSaveFinalTime}>Save</button>
    </div>
  )}

  {/* Callback Time Popup */}
  {callbackTimePopupVisible && (
    <div
      className="callback-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Callback Time</h4>
      <input
        type="date"
        value={callbackDate}
        onChange={(e) => setCallbackDate(e.target.value)}
      />
      <input
        type="time"
        value={callbackTime}
        onChange={(e) => setCallbackTime(e.target.value)}
      />
      <button onClick={handleSaveCallbackTime}>Save</button>
    </div>
  )}

  {/* Hired Popup */}
  {hiredPopupVisible && (
    <div
      className="hired-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
    <h4>Pre-Licensing Information</h4>
    <label>
      Resident State:
      <select value={residentState} onChange={(e) => setResidentState(e.target.value)}>
  <option value="">Select State</option>
  <option value="AL">Alabama</option>
  <option value="AK">Alaska</option>
  <option value="AZ">Arizona</option>
  <option value="AR">Arkansas</option>
  <option value="CA">California</option>
  <option value="CO">Colorado</option>
  <option value="CT">Connecticut</option>
  <option value="DE">Delaware</option>
  <option value="FL">Florida</option>
  <option value="GA">Georgia</option>
  <option value="HI">Hawaii</option>
  <option value="ID">Idaho</option>
  <option value="IL">Illinois</option>
  <option value="IN">Indiana</option>
  <option value="IA">Iowa</option>
  <option value="KS">Kansas</option>
  <option value="KY">Kentucky</option>
  <option value="LA">Louisiana</option>
  <option value="ME">Maine</option>
  <option value="MD">Maryland</option>
  <option value="MA">Massachusetts</option>
  <option value="MI">Michigan</option>
  <option value="MN">Minnesota</option>
  <option value="MS">Mississippi</option>
  <option value="MO">Missouri</option>
  <option value="MT">Montana</option>
  <option value="NE">Nebraska</option>
  <option value="NV">Nevada</option>
  <option value="NH">New Hampshire</option>
  <option value="NJ">New Jersey</option>
  <option value="NM">New Mexico</option>
  <option value="NY">New York</option>
  <option value="NC">North Carolina</option>
  <option value="ND">North Dakota</option>
  <option value="OH">Ohio</option>
  <option value="OK">Oklahoma</option>
  <option value="OR">Oregon</option>
  <option value="PA">Pennsylvania</option>
  <option value="RI">Rhode Island</option>
  <option value="SC">South Carolina</option>
  <option value="SD">South Dakota</option>
  <option value="TN">Tennessee</option>
  <option value="TX">Texas</option>
  <option value="UT">Utah</option>
  <option value="VT">Vermont</option>
  <option value="VA">Virginia</option>
  <option value="WA">Washington</option>
  <option value="WV">West Virginia</option>
  <option value="WI">Wisconsin</option>
  <option value="WY">Wyoming</option>
</select>
    </label>
    <label>
      Enrolled:
      <select value={enrolled} onChange={(e) => setEnrolled(e.target.value)}>
        <option value="">Select</option>
        <option value="y">Yes</option>
        <option value="n">No</option>
      </select>
    </label>
    <label>
      Course:
      <input type="text" value={course} onChange={(e) => setCourse(e.target.value)} />
    </label>
    <label>
      Expected Completion Date:
      <input type="date" value={expectedCompleteDate} onChange={(e) => setExpectedCompleteDate(e.target.value)} />
    </label>
    <button onClick={handleSaveHiredInfo}>Save</button>
  </div>
)}

  </div>
)}
</div>
</TabPanel>

<TabPanel>
{/* Hired Table */}
<div className="recruiting-table-container">
<h3>
  <span>Hired</span>

</h3>

    <table className="recruiting-table">
    <thead>
      <tr>
        <th>Recruiting Agent</th>
        <th>Applicant</th>
        <th>Res State</th>
        <th>Date</th>
        <th>Last Updated</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Aspects</th>
        <th>Concern</th>
        <th>Spouse</th>
        <th>Career Goals</th>
        <th>Compensation</th>
        <th>Why Choose</th>
      </tr>
    </thead>
    <tbody>
      {hiredApplicants.map((applicant) => (
        <tr
          key={applicant.id}
          onClick={(e) => handleActionClick(e, applicant)}
          ref={rowRef}
          className={menuVisible && selectedApplicant?.id === applicant.id ? 'popup-open' : ''}
        >
          <td className="applicants-column-long">{applicant.lagnname}</td>
          <td>{formatApplicantName(applicant.recruit_first, applicant.recruit_middle, applicant.recruit_last, applicant.recruit_suffix)}</td>
          <td>{applicant.resident_state}</td>
          <td>{formatDateTime(applicant.date_added)}</td>
          <td>{formatDateTime(applicant.date_last_updated)}</td>
          <td>{applicant.phone}</td>
          <td>{applicant.email}</td>
          <td className="applicants-column-long">{applicant.Aspects}</td>
          <td className="applicants-column-long">{applicant.Concern}</td>
          <td className="applicants-column-long">{applicant.Spouse}</td>
          <td className="applicants-column-long">{applicant.CareerGoals}</td>
          <td className="applicants-column-long">{applicant.Compensation}</td>
          <td className="applicants-column-long">{applicant.WhyChoose}</td>
        </tr>
      ))}
    </tbody>
  </table>
  {menuVisible && (
  <div
    ref={menuRef}
    className="popup-menu"
    style={{
      position: 'fixed',
      top: menuPosition.y,
      left: menuPosition.x,
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      padding: '8px',
      borderRadius: '4px',
    }}
  >
    <button onClick={() => handleMenuOptionClick('Booked Final')}>Booked Final</button>
    <button onClick={() => handleMenuOptionClick("Didn't Hire")}>Didn't Hire</button>
    <button onClick={() => handleMenuOptionClick("Called No Answer")}>Called No Answer</button>
    <button onClick={() => handleMenuOptionClick("Called Schedule Callback")}>Called Schedule Callback</button>
    <button onClick={() => handleMenuOptionClick("Move to Applicants")}>Move to Applicants</button> {/* New Option */}
    <button onClick={() => handleMenuOptionClick("Hired")}>Hired</button> {/* New Option */}
    {finalTimePopupVisible && (
      <div
      className="final-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Final Time</h4>
      <input
        type="date"
        value={finalDate}
        onChange={(e) => setFinalDate(e.target.value)}
      />
      <input
        type="time"
        value={finalTime}
        onChange={(e) => setFinalTime(e.target.value)}
      />
      <button onClick={handleSaveFinalTime}>Save</button>
    </div>
  )}

  {/* Callback Time Popup */}
  {callbackTimePopupVisible && (
    <div
      className="callback-time-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
      <h4>Set Callback Time</h4>
      <input
        type="date"
        value={callbackDate}
        onChange={(e) => setCallbackDate(e.target.value)}
      />
      <input
        type="time"
        value={callbackTime}
        onChange={(e) => setCallbackTime(e.target.value)}
      />
      <button onClick={handleSaveCallbackTime}>Save</button>
    </div>
  )}

  {/* Hired Popup */}
  {hiredPopupVisible && (
    <div
      className="hired-popup"
      style={{
        position: 'fixed',
        top: menuPosition.y,
        left: menuPosition.x + 200, // Offset to the right
        backgroundColor: '#f9f9f9',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1100,
      }}
    >
    <h4>Pre-Licensing Information</h4>
    <label>
      Resident State:
      <select value={residentState} onChange={(e) => setResidentState(e.target.value)}>
  <option value="">Select State</option>
  <option value="AL">Alabama</option>
  <option value="AK">Alaska</option>
  <option value="AZ">Arizona</option>
  <option value="AR">Arkansas</option>
  <option value="CA">California</option>
  <option value="CO">Colorado</option>
  <option value="CT">Connecticut</option>
  <option value="DE">Delaware</option>
  <option value="FL">Florida</option>
  <option value="GA">Georgia</option>
  <option value="HI">Hawaii</option>
  <option value="ID">Idaho</option>
  <option value="IL">Illinois</option>
  <option value="IN">Indiana</option>
  <option value="IA">Iowa</option>
  <option value="KS">Kansas</option>
  <option value="KY">Kentucky</option>
  <option value="LA">Louisiana</option>
  <option value="ME">Maine</option>
  <option value="MD">Maryland</option>
  <option value="MA">Massachusetts</option>
  <option value="MI">Michigan</option>
  <option value="MN">Minnesota</option>
  <option value="MS">Mississippi</option>
  <option value="MO">Missouri</option>
  <option value="MT">Montana</option>
  <option value="NE">Nebraska</option>
  <option value="NV">Nevada</option>
  <option value="NH">New Hampshire</option>
  <option value="NJ">New Jersey</option>
  <option value="NM">New Mexico</option>
  <option value="NY">New York</option>
  <option value="NC">North Carolina</option>
  <option value="ND">North Dakota</option>
  <option value="OH">Ohio</option>
  <option value="OK">Oklahoma</option>
  <option value="OR">Oregon</option>
  <option value="PA">Pennsylvania</option>
  <option value="RI">Rhode Island</option>
  <option value="SC">South Carolina</option>
  <option value="SD">South Dakota</option>
  <option value="TN">Tennessee</option>
  <option value="TX">Texas</option>
  <option value="UT">Utah</option>
  <option value="VT">Vermont</option>
  <option value="VA">Virginia</option>
  <option value="WA">Washington</option>
  <option value="WV">West Virginia</option>
  <option value="WI">Wisconsin</option>
  <option value="WY">Wyoming</option>
</select>
    </label>
    <label>
      Enrolled:
      <select value={enrolled} onChange={(e) => setEnrolled(e.target.value)}>
        <option value="">Select</option>
        <option value="y">Yes</option>
        <option value="n">No</option>
      </select>
    </label>
    <label>
      Course:
      <input type="text" value={course} onChange={(e) => setCourse(e.target.value)} />
    </label>
    <label>
      Expected Completion Date:
      <input type="date" value={expectedCompleteDate} onChange={(e) => setExpectedCompleteDate(e.target.value)} />
    </label>
    <button onClick={handleSaveHiredInfo}>Save</button>
  </div>
)}

  </div>
)}
</div>
</TabPanel>

</Tabs>

      </div>
  );
};

export default Applicants;
