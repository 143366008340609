import React, { useState, useEffect } from 'react';
import Checklist from './Checklist';
import AgentProgressTable from './AgentProgressTable';

const Release = () => {
  const [view, setView] = useState('checklist');
  const [showTabs, setShowTabs] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem('userRole');
    if (userRole === 'Admin') {
      setIsAdmin(true);
    } else if (userRole !== 'AGT') {
      setShowTabs(true);
    }
  }, []);

  return (
    <div>
      {isAdmin ? (
        <AgentProgressTable />
      ) : (
        <>
          {showTabs && (
            <div className="tabs">
              <input
                type="radio"
                id="checklist"
                name="view_type"
                value="checklist"
                checked={view === 'checklist'}
                onChange={() => setView('checklist')}
              />
              <label htmlFor="checklist">Checklist</label>

              <input
                type="radio"
                id="table"
                name="view_type"
                value="table"
                checked={view === 'table'}
                onChange={() => setView('table')}
              />
              <label htmlFor="table">Table</label>
            </div>
          )}

          {view === 'checklist' && <Checklist />}
          {view === 'table' && <AgentProgressTable />}
        </>
      )}
    </div>
  );
};

export default Release;
